import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card, CardContent, FormControl, Grid, Typography, Box,
    TextField
} from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import { EditionPartHierarchie, EditionPartHierarchieSummary } from './EditionPartHierarchie';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import {
    selectedMarket, tenant, selectedEditionPart, orderCatchword, editionPartsHierarchy
} from '../../redux/Selectors.js';
import { setOrderCatchword, setEditionPart } from '../../redux/Actions';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const EditionPartCard = forwardRef(({ height }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _tenant = useSelector((state) => tenant(state));
    const _orderCatchword = useSelector((state) => orderCatchword(state));
    const _editionPartsHierarchy = useSelector((state) => editionPartsHierarchy(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));

    const [catchword, setCatchword] = useState('');
    const [noEditionPartSelection, setNoEditionPartSelection] = useState(false);

    useImperativeHandle(ref, () => ({
        saveData: () => {
            saveData();
        }
    }));


    useEffect(() => {
        //   catchword
        setCatchword(_orderCatchword);

        // is more than one editionPart available
        setNoEditionPartSelection(_editionPartsHierarchy?.length === 1 && _editionPartsHierarchy[0].editionParts?.length === 0)
    }, []);

    useEffect(() => {
        if (noEditionPartSelection) {
            dispatch(setEditionPart(_editionPartsHierarchy[0]._id));
        }
    }, [noEditionPartSelection])

    const handleCatchwordChange = (event) => {
        setCatchword(event.target.value);
    };

    const saveData = () => {
        console.log("saveData: write catchword to redux: " + catchword);
        dispatch(setOrderCatchword(catchword));
    }

    const Label = ({ text }) => {
        return (
            <Typography
                sx={{ fontSize: 16 }}
                noWrap >
                {t(text)}:
            </Typography>
        );
    }

    const DataBold = ({ text }) => {
        return (
            <Typography variant='textBold'>
                {text}
            </Typography>
        );
    }

    const getStyleBold = () => {
        return (
            {
                fontFamily: 'ABeZehBold, sans-serif',
                fontSize: 16,
                color: '#191919',
                opacity: 1,
                letterSpacing: 0,
                fontWeight: 'bold',
                mb: 4
            }
        );
    }


    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('schedule.editionPartCardTitle')} />
            <CardContent sx={{ pt: 4, height: height - 40, overflowY: "auto" }}>
                <Box
                    sx={{
                        mb: 2,
                        mt: 9,
                        height: '100%'
                    }}
                >

                    <form >
                        <FormControl sx={{ width: '100%' }}>
                            <Grid container width='100%' spacing='10'>
                                <Grid item xs={4} >
                                    <Label text='order.category' />
                                </Grid>
                                <Grid item xs={8}>
                                    <DataBold text={_selectedMarket ? _selectedMarket.name : 'xxx'} />
                                </Grid>

                                <Grid item xs={4} >
                                    <Box sx={{ pt: 8 }}>
                                        <Label text='order.editionPart' />
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    {noEditionPartSelection ? <Box sx={{ pt: 8 }}><EditionPartHierarchieSummary variant='oneLevel' /></Box> : <Box sx={{ mt: _selectedEditionPart !== null ? 8 : -2 }}><EditionPartHierarchie variant='vertical' styleSelect={getStyleBold} /></Box>}
                                </Grid>


                                {_tenant?.orderCatchword && (
                                    <>
                                        <Grid item xs={4} >
                                            <Box sx={{ pt: 21 }}>
                                                <Label text='order.catchword' />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box sx={{ pt: 19 }}>
                                                <TextField
                                                    variant='standard'
                                                    value={catchword}
                                                    inputProps={{ maxLength: _tenant.orderCatchword }}
                                                    onChange={handleCatchwordChange}
                                                    sx={{ width: 300 }} />
                                            </Box>
                                        </Grid>
                                    </>
                                )}


                            </Grid>
                        </FormControl>
                    </form >
                </Box>
            </CardContent >
        </Card >
    );

});