import { useRef, useEffect, StrictMode, PropsWithChildren } from "react";
import { Root, createRoot } from "react-dom/client";

const ShadowRender = (props) => {
    const containerRef = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
        if (containerRef.current && !rootRef.current) {
            rootRef.current = createRoot(
                containerRef.current.attachShadow({ mode: "open" })
            );
        }

        if (rootRef.current) {
            const styles = props.styles
                ? `:host {
        all: initial
      }
      
      ${props.styles}`
                : "";
            rootRef.current.render(
                <StrictMode>
                    <style>{styles}</style>
                    {props.children}
                </StrictMode>
            );
        }
    }, [props.children, props.styles]);

    return <div ref={containerRef} />;
};

export default ShadowRender;