import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Form from './LoginForm';
import { Alert, AlertTitle, DialogTitle, DialogActions, DialogContentText, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from '../AppTheme';
import { properties } from '../redux/Selectors.js';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ModalDialog = ({ open, handleClose, title }) => {
    const _properties = useSelector((state) => properties(state));
    const theme = createTheme(appTheme(_properties));
    return (
        // props received from App.js
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Paper elevation={0}>
                        <Form handleClose={handleClose} />
                    </Paper>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};

export default ModalDialog;

export const ConfirmationDialog = ({ okAction, cancelAction, msg, open, ...props }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} {...props}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    dangerouslySetInnerHTML={{
                        __html: msg
                    }} />
                <DialogActions>
                    <Button onClick={okAction}>
                        {t('common.yes')}
                    </Button>
                    <Button autoFocus onClick={cancelAction}>
                        {t('common.no')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export const MessageBox = ({ open, handleClose, title, message, severity }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open}>
            {title && (
                <DialogTitle>{t(title)}</DialogTitle>
            )}
            <Alert severity={severity} >
                {t(message)}
            </Alert>
            <Button autoFocus onClick={handleClose}>
                {t('main.ok')}
            </Button>
        </Dialog>
    );
}
