import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, FormControl, Grid, Typography, Box } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import { possibleDates, selectedOffer } from '../../redux/Selectors';
import { StaticDesiredDatePicker } from './DesiredDatePicker';
import { PublicationDatePicker } from './PublicationDatePicker';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CalendarCard = ({ height, monthsShown, orientation }) => {
    const { t } = useTranslation();

    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _possibleDates = useSelector((state) => possibleDates(state));

    const [contentStyle, setContentStyle] = useState({})

    useEffect(() => {
        let cs = {};
        if (_selectedOffer.publicationCalendar && (!_possibleDates || _possibleDates.length === 0)) {
            cs = {
                minHeight: '100%',
                backgroundColor: 'action.disabled',
                color: 'primary.contrastText',
                padding: 12,
                overflowY: "auto"
            }
        } else {
            cs = { pt: 4, height: height - 40, backgroundColor: '#FFFFFF', overflow: "hidden", overflowY: "auto" }
        }
        console.log("setContentStyle: " + JSON.stringify(cs));
        setContentStyle(cs);
    }, [_possibleDates])

    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t(_selectedOffer.publicationCalendar ? 'schedule.calendarCardTitle' : 'schedule.desiredCalendarCardTitle')} />
            <CardContent sx={contentStyle}>
                <Box
                    sx={{
                        mb: 2,
                        height: '100%'
                    }}
                >
                    {!_selectedOffer.publicationCalendar ? <StaticDesiredDatePicker monthsShown={monthsShown} orientation={orientation} /> : <PublicationDatePicker variant="static" monthsShown={monthsShown} />}
                </Box>
            </CardContent >
        </Card >
    );

}