import React, { useEffect, useState, useRef, Suspense, useImperativeHandle } from 'react';
import ReactShadowRoot from 'react-shadow-root';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { offercriterias, selectedOffer, selectedEditionIds, device } from '../../redux/Selectors.js';
import { setEditions } from '../../redux/Actions.js';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';
import MsgPopper from '../common/MsgPopper.jsx';
import ShadowRender from '../../common/renderShadow.js';

export const EditionsPicker = React.forwardRef(({ width, height, variant, contextId, ignoreLocalPosition }, ref) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _selectedEditionIds = useSelector((state) => selectedEditionIds(state));
    const _device = useSelector(state => device(state));

    const [editionImages, setEditionImages] = useState([]);
    const [position, setPosition] = useState();
    const [offer, setOffer] = useState(JSON.parse(JSON.stringify(_selectedOffer)));
    const [ctx, setCtx] = useState(null);
    const [selectionModified, setSelectionModified] = useState(false);
    const [mousePosition, setMousePosition] = useState();
    const [lastLayer, setLastLayer] = useState();
    const [coordinates, setCoordinates] = useState([0, 0])
    const [msgEditions, setMsgEditions] = useState();
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [activePicker, setActivePicker] = useState(true)

    const background = useRef();
    const panel = useRef();
    const locationIcon = useRef();
    const canvas = useRef();
    const layer = useRef([]);

    useImperativeHandle(ref, () => ({ saveEditions }));



    const saveEditions = () => {
        dispatch(setEditions(JSON.parse(JSON.stringify(offer.preSelectedEditions))));
    }

    useEffect(() => {
        if (_selectedOffer?.minEditionsSelectable && _selectedOffer.maxEditionsSelectable) {
            if (_selectedOffer.minEditionsSelectable === _selectedOffer.maxEditionsSelectable) {
                setMsgEditions(t('order.equalEditionsMessage', { min: _selectedOffer.minEditionsSelectable }));
            } else {
                setMsgEditions(t('order.minMaxEditionsMessage', { min: _selectedOffer.minEditionsSelectable, max: _selectedOffer.maxEditionsSelectable }));
            }
        } else if (_selectedOffer.minEditionsSelectable) {
            setMsgEditions(t('order.minEditionsMessage', { min: _selectedOffer.minEditionsSelectable }));
        } else if (_selectedOffer.minEditionsSelectable) {
            setMsgEditions(t('order.maxEditionsMessage', { max: _selectedOffer.maxEditionsSelectable }));
        }
    }, [_selectedOffer]);

    useEffect(() => {
        window.addEventListener('resize', backgroundLoaded({ "target": background }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (offercriterias) {
            let edImg = [];
            for (var i in offer.editionImages) edImg.push(offer.editionImages[i]);
            setEditionImages(edImg);
        }
        return (() => {
            setMsgOpen(false);
        });
    }, [offer]);

    useEffect(() => {
        if (_selectedOffer?.editionsSelectable) {
            console.log("Check geolocation ...");
            if (!position && _selectedEditionIds === _selectedOffer.preSelectedEditions) {
                var pos = navigator.geolocation ? new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, ({ code, message }) =>
                    reject(Object.assign(new Error(message), { name: "PositionError", code })))) : Promise.reject("Error");
                pos.then((pos) => {
                    console.log("geolocation successfull ...");
                    console.log(pos)
                });
                setPosition(pos);
            }

            let selOffer = JSON.parse(JSON.stringify(_selectedOffer));
            if (_selectedEditionIds?.length > 0) {
                console.log("set preSelectedEditions to " + JSON.stringify(_selectedEditionIds));
                selOffer.preSelectedEditions = _selectedEditionIds;
            }
            setOffer(selOffer);

        }
    }, [_selectedOffer]);

    useEffect(() => {
        // console.log("useEffect mousePosition ....");
        // console.log(mousePosition);
        if (ctx && mousePosition) {
            //           var e = { target: { clientWidth: mousePosition.left, clientHeight: mousePosition.top }, offsetWidth: mousePosition.offsetWidth, offsetHeight: mousePosition.offsetHeight }

            var curLayer = getLayer(mousePosition);
            //            console.log(curLayer);
            if (curLayer !== lastLayer) {
                setLastLayer(curLayer);
                layer.current.forEach((l, i) => {
                    // console.log("l.style.opacity = " + l.style.opacity);
                    if (l.style && l.style.opacity !== '1') l.style.opacity = (curLayer && i === curLayer - 1) ? 0.3 : 0;
                });
                if (offer.preSelectedEditions.length === offer.maxEditionsSelectable || offer.preSelectedEditions.length < offer.minEditionsSelectable) {
                    var x = Math.round(canvas.current.width / mousePosition.target.clientWidth * mousePosition.nativeEvent.offsetX);
                    var y = Math.round(canvas.current.height / mousePosition.target.clientHeight * mousePosition.nativeEvent.offsetY);
                    setCoordinates([x, y]);
                    var text = offer.preSelectedEditions.length === offer.maxEditionsSelectable ? t('order.maxEdtionsSelectedMessage') : t('order.minEdtionsSelectedMessage', { min: _selectedOffer.minEditionsSelectable });
                    // console.log(text);
                    setMsgText(text);
                    setMsgOpen(true);
                } else {
                    setMsgOpen(false);
                }
            }
        }

    }, [mousePosition]);

    useEffect(() => {
        setSelectionModified(false);
    }, [offer.editionImages]);

    const getLayer = (e) => {
        if (ctx && canvas) {
            var data = ctx.getImageData(0, 0, canvas.current.width, canvas.current.height).data;
            var x = Math.round(canvas.current.width / e.target.clientWidth * e.nativeEvent.offsetX);
            var y = Math.round(canvas.current.height / e.target.clientHeight * e.nativeEvent.offsetY);
            //            console.log("mousePos: x = " + x + " y = " + y);
            return data[(y * canvas.current.width + x) * 4 + 3];
        }
    }

    function handleMouseMove(ev) { setMousePosition(ev); }

    function handleMouseOut(ev) {
        //        console.log("onMouseOut: " + ev.target.id);
        //        console.log(ev);
        if (ev.target.id === "EditionPickerBox") {
            setMsgOpen(false);
        }
    }

    const backgroundUrl = () => {
        return _selectedOffer?.editionBackground ? _selectedOffer.editionBackground._links.image.href + "?d=" + Math.random() : "";
    }

    const backgroundLoaded = (e) => {
        //        console.log("backgroundLoaded ...");
        var background = e.target;
        if (panel?.current?.style?.maxWidth) {
            panel.current.style.maxWidth = background?.naturalWidth + "px";
            console.log(position);
            if (position && offer.locationLeft && offer.locationTop && offer.locationRight && offer.locationBottom) position.then(pos => {
                console.log("Latitude is :", pos?.coords?.latitude);
                console.log("Longitude is :", pos?.coords?.longitude);
                var dx = background.clientWidth / (offer.locationRight - offer.locationLeft) * (pos.coords.longitude - offer.locationLeft);
                var dy = background.clientHeight / (offer.locationTop - offer.locationBottom) * (offer.locationTop - pos.coords.latitude);
                if (dx >= 0 && dx <= background.clientWidth && dy >= 0 && dy <= background.clientHeight) {
                    locationIcon.current.style.left = (dx - 12) + "px";
                    locationIcon.current.style.top = (dy - 22) + "px";
                    locationIcon.current.style.display = "block";
                }
            }).catch(e => { });
        }
    }

    const toggleEdition = (e) => {
        // console.log("toggleEdition ....");
        // console.log(e);
        if (ctx) {
            var curLayer = getLayer(e);
            if (curLayer) {
                var target = layer.current[curLayer - 1];
                if (target) {
                    var i = offer.preSelectedEditions.indexOf(Number(target.name));
                    var curOffer = offer;
                    if (i !== -1 || offer.preSelectedEditions.length !== offer.maxEditionsSelectable) {
                        if (i !== -1) curOffer.preSelectedEditions.splice(i, 1);
                        else curOffer.preSelectedEditions.push(Number(target.name));
                        curOffer.preSelectedEditions = curOffer.preSelectedEditions.splice(0);
                        selectionChanged(curOffer.editionImages, curOffer.preSelectedEditions);
                        curOffer.lockSelection = true;
                        setOffer(curOffer);
                    }
                }
                e.stopPropagation();
            }
        }
    }

    const selectionChanged = (editionImages, selection) => {
        //       console.log("lastSelection = " + JSON.stringify(lastSelection.current));
        console.log("selection = " + JSON.stringify(selection));
        console.log(contextId + ": activePicker = " + activePicker);
        //       let idsChanged = JSON.stringify(selection) !== JSON.stringify(lastSelection.current);
        if (editionImages && selection && offer?.editionsSelectable && contextId === "card") {
            console.log("selectionChanged(...) selection = " + selection);
            dispatch(setEditions(JSON.parse(JSON.stringify(selection))));
            setMsgOpen(false);
        }
        if (selection) layer.current.forEach((l, i) => l.style.opacity = selection.indexOf(Number(l.name)) !== -1 ? 1 : 0);
    }


    const calculateMap = (e) => {
        console.log("calculateMap ...");
        var curLayer = e.target;
        console.log(curLayer);
        var ctxLoc;
        if (curLayer.naturalWidth !== canvas.current.width || curLayer.naturalHeight !== canvas.current.height) {
            canvas.current.width = curLayer.naturalWidth;
            canvas.current.height = curLayer.naturalHeight;
            ctxLoc = canvas.current.getContext("2d");
            //       setCtx(ctxLoc);
            ctxLoc.clearRect(0, 0, canvas.current.width, canvas.current.height);
        } else {
            ctxLoc = ctx;
        }
        if (ctxLoc !== null) {
            console.log(ctxLoc);
            var data = ctxLoc?.getImageData(0, 0, curLayer.naturalWidth, curLayer.naturalHeight).data;
            ctxLoc?.drawImage(curLayer, 0, 0);
            var id2 = ctxLoc?.getImageData(0, 0, curLayer.naturalWidth, curLayer.naturalHeight);
            var data2 = id2.data;
            for (var i = 3; i < curLayer.naturalHeight * curLayer.naturalWidth * 4 + 3; i += 4) {
                if (data2[i] !== data[i]) {
                    data2[i] = Number(curLayer.attributes.index.value) + 1;
                    //                console.log("data2[" + i + "] = " + data2[i]);
                }
            }
            console.log(id2);
            ctxLoc?.putImageData(id2, 0, 0);
            if (offer) {
                var curOffer = offer;
                if (!offer.lockSelection && position && offer.locationLeft && offer.locationTop && offer.locationRight && offer.locationBottom) position.then(pos => {
                    var setCurLayer = () => {
                        if (!selectionModified) {
                            curOffer.preSelectedEditions = [Number(curLayer.name)];
                            setOffer(curOffer);
                            setSelectionModified(true);
                        } else curOffer.preSelectedEditions.push(Number(curLayer.name));
                        curOffer.preSelectedEditions = curOffer.preSelectedEditions.splice(0);
                        selectionChanged(curOffer.editionImages, curOffer.preSelectedEditions);
                    };
                    var dx = Math.round(curLayer.naturalWidth / (offer.locationRight - offer.locationLeft) * (pos.coords.longitude - offer.locationLeft));
                    var dy = Math.round(curLayer.naturalHeight / (offer.locationTop - offer.locationBottom) * (offer.locationTop - pos.coords.latitude));
                    var l = Number(curLayer.attributes.index.value) + 1;
                    if (data2[(dy * curLayer.naturalWidth + dx) * 4 + 3] === l) setCurLayer();
                    else if (offer.locationDistance) {		// nearest layers only if distance configured
                        var km = Number.MAX_VALUE;
                        for (var y = 0; y < curLayer.naturalHeight; y++) {
                            for (var x = 0; x < curLayer.naturalWidth; x++) {
                                var i = (y * curLayer.naturalWidth + x) * 4 + 3;
                                if (data2[i] === l && (x === 0 || data2[i - 4] !== l || x === curLayer.naturalWidth - 1 || data2[i + 4] !== l)) {	// check border points only
                                    km = Math.min(km, distanceInKm(pos.coords.latitude, pos.coords.longitude,
                                        offer.locationTop - y / curLayer.naturalHeight * (offer.locationTop - offer.locationBottom),
                                        offer.locationLeft + x / curLayer.naturalWidth * (offer.locationRight - offer.locationLeft)));
                                }
                            }
                        }
                        console.log("Ausgabe = " + curLayer.name + " / km = " + km)
                        if (km < offer.locationDistance) setCurLayer();
                    }
                }).catch(e => { });
                setCtx(ctxLoc);
                selectionChanged(offer.editionImages, offer.preSelectedEditions);
            }
            //         } else {
            if (offer.preSelectedEditions) layer.current.forEach((l, i) => l.style.opacity = offer.preSelectedEditions.indexOf(Number(l.name)) !== -1 ? 1 : 0);
            //         }
        }
    }

    const distanceInKm = (lat1, lon1, lat2, lon2) => {		// Haversine formula
        var radius = 6371;
        var lat = Math.PI * (lat2 - lat1) / 180;
        var lon = Math.PI * (lon2 - lon1) / 180;
        var a = Math.sin(lat / 2) * Math.sin(lat / 2) + Math.cos(Math.PI * lat1 / 180) * Math.cos(Math.PI * lat2 / 180) * Math.sin(lon / 2) * Math.sin(lon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = radius * c;
        return Math.abs(d);
    }

    const layerStyle = {
        height: 'inherit',
        width: 'inherit',
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
    };

    const styles = `:host {
    display: block;
   }
   .layer, .click {
				height: inherit;
				width: inherit;
				position: absolute;
				left: 0px;
				top: 0px;
				opacity: 0;
			}

			img {
				user-select: none;
			}`;

    const Loading = () => {
        return (
            <div height="200px">Loading ...</div>
        )
    }

    /* Does not work with mui components */

    return (
        <Box id="EditionPickerBox" onMouseOut={handleMouseOut} sx={{ p: 0, m: 0, height: '100%' }}>
            <ShadowRender>
                <style>{styles}</style>
                <Suspense fallback={<Loading />}>
                    <div ref={panel}
                        style={{ width: _device === 'mobile' ? '100%' : '95%', height: 'auto', position: 'relative' }}
                        onClick={(ev) => toggleEdition(ev)}
                        onMouseMove={(ev) => handleMouseMove(ev)}>
                        <img
                            src={backgroundUrl()}
                            ref={background}
                            style={{ width: 'inherit', height: 'inherit' }}
                            onLoad={backgroundLoaded}
                            alt=""
                        />
                        {editionImages?.map((edImg, index) => {
                            if (edImg._links?.image?.href) {
                                return (
                                    < img
                                        key={index}
                                        src={edImg._links?.image?.href}
                                        onLoad={calculateMap}
                                        alt=""
                                        name={edImg.editionId}
                                        className="layer"
                                        style={layerStyle}
                                        ref={(element) => { layer.current[index] = element }}
                                        id={'layer' + index}
                                        index={index}
                                    />
                                )
                            } else {
                                return (<></>);
                            }
                        })
                        }
                        <LocationOnIcon ref={locationIcon} style={{ display: 'none' }} />
                    </div >
                </Suspense>
                <Box >
                    <Typography variant="subtitle1">{msgEditions}</Typography>
                </Box>

                <canvas ref={canvas} />
            </ShadowRender>
            <MsgPopper
                open={msgOpen}
                arrow={true}
                anchorRef={background}
                placement={variant === 'mobile' ? 'bottom' : 'left'}
                text={msgText}
            >
            </MsgPopper>

        </Box>
    );
});