import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
    allTemplates, 
    preSelectedTemplate,
    properties, 
    selectedMarket, 
    templates, 
} from '../redux/Selectors.js';
import {
    setTemplate,
    setAdBoxNumber,
    resetEditedOrder,
    resetRepeatedOrder,
} from '../redux/Actions.js';

import { fetchTemplateDependencies } from '../service/CreativeService';
import { TemplateInfo } from './common/Commons';

import HtmlTooltip from '@mui/material/Tooltip';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    ImageList,
    ImageListItem,
    Pagination,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';

import { styled } from '@mui/material/styles';
import { DEVICE_DESKTOP, getPage } from '../common/navigationTools.js';

const Item = styled(Paper)(({ theme }) => ({
    border: 'none',
    boxShadow: 'none'
}));

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const TemplatePicker = ({ xHeight }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _allTemplates = useSelector((state) => allTemplates(state));
    const _preSelectedTemplate = useSelector((state) => preSelectedTemplate(state));
    const _properties = useSelector((state) => properties(state));
    const _templates = useSelector((state) => templates(state));

    const [rows, setRows] = useState(2);
    const [columns, setColumns] = useState(4);
    const [itemHeight, setItemHeight] = useState(Math.floor((window.innerHeight) / rows));
    const [pickerHeight, setPickerHeight] = useState(itemHeight * rows + 40);
    const [innerPickerHeight, setInnerPickerHeight] = useState(pickerHeight - 30);
    const [bricksHeight, setBricksHeight] = useState();
    //npm   const [imageColumns, setImageColumns] = useState(5);


    const listenResize = () => {
        setBricksHeight(Math.floor(window.innerHeight - 300));
        //    setImageColumns(Math.floor(window.innerWidth / 200))
        let tmpItemHeight = Math.floor((window.innerHeight - 280) / rows);
        let tmpPickerHeight = tmpItemHeight * rows + 40;
        let tmpInnerPickerHeight = tmpPickerHeight - 30;
        setItemHeight(tmpItemHeight);
        setPickerHeight(tmpPickerHeight);
        setInnerPickerHeight(tmpInnerPickerHeight);
        let tmpRows = 2;
        let tmpColumns = 4;
        if (window.innerWidth < 300) {
            tmpRows = 100;
            tmpColumns = 1;
            setPickerHeight('auto');
            setInnerPickerHeight('auto');
        } else if (window.innerWidth < 600) {
            tmpRows = 2;
            tmpColumns = 1;
        } else if (window.innerWidth < 900) {
            tmpRows = 2;
            tmpColumns = 2;
        } else if (window.innerWidth < 1200) {
            tmpRows = 2
            tmpColumns = 3;
        } else {
            tmpRows = 2;
            tmpColumns = 4;
        }
        setRows(tmpRows);
        setColumns(tmpColumns);
        setItemsPerPage(tmpRows * tmpColumns);
    };

    useEffect(() => {
        listenResize();
        window.addEventListener('resize', listenResize);
        if (_preSelectedTemplate) {
            templateSelected(null, _preSelectedTemplate._id);
        }
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        listenResize();
    }, [xHeight]);

    const templateSelected = (e, itemId) => {
        dispatch(resetRepeatedOrder());
        dispatch(resetEditedOrder());

        const t = _templates.find(it => it._id === Number(itemId));
        fetchTemplateDependencies(t).then(id => {
            dispatch(setTemplate(id));
            dispatch(setAdBoxNumber());
            navigate(getPage('creative', DEVICE_DESKTOP));
        });
    };

    const createMarkup = (value) => {
        return { __html: value };
    };

    const [itemsPerPage, setItemsPerPage] = React.useState(rows * columns);
    const [page, setPage] = React.useState(1);
    const [noOfPages, setNoOfPages] = React.useState(
        Math.ceil(_templates.length / itemsPerPage)
    );

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        setNoOfPages(Math.ceil(_templates.length / itemsPerPage));
        setPage(page);
    }, [_templates, itemsPerPage]);

    const Tiles = () => {
        return <Stack spacing={4} direction="column" justifyContent="space-between" sx={{ height: pickerHeight }} >
            <Item sx={{ height: (innerPickerHeight) }}>
                <Grid container>
                    {_templates
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map(item => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                                    <Box sx={{ height: itemHeight }}>
                                        <Card onClick={(e) => { templateSelected(e, item._id) }}
                                            sx={{ height: '100%', border: 'none', boxShadow: 'none' }}>
                                            <CardActionArea sx={{ height: '100%' }} >
                                                <Box sx={{ height: itemHeight / 2 + 70, border: 1, borderRadius: 2.5, opacity: 1, color: '#A0A0A0', p: 1, mt: 2, ml: 2, mr: 2, mb: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                    <CardMedia
                                                        component="img"
                                                        image={item._links.image.href}
                                                        sx={{ maxHeight: itemHeight / 2 + 50, height: 'auto', width: 'auto', overflow: 'hidden', margin: 'auto' }}
                                                    />
                                                </Box>
                                                <CardContent sx={{ height: itemHeight / 2, p: 2 }}>
                                                    <Stack alignItems='center'>
                                                        <Typography
                                                            variant='templatePickerTitle'
                                                            dangerouslySetInnerHTML={createMarkup(item.htmlTitle || item.name)}></Typography>
                                                        <Typography
                                                            variant='templatePickerSubtitle'
                                                            dangerouslySetInnerHTML={createMarkup(item.htmlText)}></Typography>
                                                    </Stack>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                </Grid>)
                        })}
                </Grid>
            </Item>

            <Box display={{ xs: 'none', sm: 'block' }}>
                <Divider />
            </Box>

            <Item>
                <Stack direction="row" justifyContent="flex-end">
                    <Box component="span" display={{ xs: 'none', sm: 'flex' }}>
                        <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChange}
                            defaultPage={page}
                            color="primary"
                            size="large"
                            showFirstButton
                            showLastButton
                            sx={{ '& .MuiPaginationItem-page': { display: 'none' } }}
                        />
                    </Box>
                </Stack>
            </Item>
        </Stack>
    };

    const Bricks = () => {

        return <Box sx={{ padding: '1%', margin: '1%', backgroundColor: /* '#AEAEAE65'*/ _properties.appBackgroundColor, overflowY: 'scroll', height: bricksHeight }}>
            <Masonry columns={{ sm: 3, md: 4, lg: 5 }} spacing={10}>
                {_templates.map((item, index) => (
                    <Box key={index} onClick={(e) => { templateSelected(e, item._id) }}
                        sx={{ boxShadow: `5px 5px 2px silver`, borderRadius: '3px' }}>
                        <HtmlTooltip
                            arrow
                            placement="right-start"
                            title={<TemplateInfo item={item}></TemplateInfo>}
                        >
                            <img
                                src={`${item._links.image.href}`}
                                loading="lazy"
                                style={{
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    display: 'block',
                                    width: '100%',
                                    height: 'auto'
                                }}
                            />
                        </HtmlTooltip>

                    </Box>
                ))}
            </Masonry>
        </Box >
    };

    const Images = () => {

        let items = _templates.filter(item => item.shortName !== 'Online_animated');

        let maxWidth = 0;
        let minWidth = 1000000;
        items.forEach(item => {
            item.w = item.imageWidth;
            item.h = item.imageHeight / 2;
        });

        _allTemplates.forEach(item => {
            if (item.w > maxWidth) {
                maxWidth = item.w;
            }
            if (item.w < minWidth) {
                minWidth = item.w;
            }
        });

        items = items.map(item => Object.assign({}, item, { cols: Math.floor(item.w / minWidth) }));
        items = items.map(item => Object.assign({}, item, { rows: Math.floor(item.h) }));

        function srcset(image, size, rows = 1, cols = 1) {
            return {
                src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
                // src: `${image}?w=auto&h=${size * rows}&fit=CONTAIN&auto=format`,
                srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
            };
        }

        return <Box sx={{ padding: '1%', margin: '1%', backgroundColor: _properties.appBackgroundColor, xoverflowY: 'scroll', xheight: bricksHeight }}>
            <ImageList
                sx={{ xwidth: '85vw', height: bricksHeight - 70 }}
                variant="quilted"
                cols={5}
                //                cols={imageColumns}
                rowHeight="auto"
            >
                {items.map((item, index) => (
                    <>
                        <HtmlTooltip
                            arrow
                            placement="right-start"
                            title={<TemplateInfo item={item}></TemplateInfo>}
                        >
                            <ImageListItem
                                key={item.img}
                                onClick={(e) => { templateSelected(e, item._id) }}
                                cols={item.cols || 1}
                                rows={item.rows || 1}
                                sx={{ cursor: 'pointer', padding: '5px', boxShadow: `1px 1px 2px silver`, borderRadius: '3px' }}
                            >
                                <img
                                    src={item._links.image.href}
                                    //src="https://hellertal.startbilder.de/1200/die-my-1149-nohab-aa16-490722.jpg"
                                    //                                    {...srcset(item._links.image.href, 10, item.rows, item.cols)}
                                    //{...srcset("https://hellertal.startbilder.de/1200/die-my-1149-nohab-aa16-490722.jpg", 400, item.rows, item.cols)}
                                    alt={item.name}
                                    loading="lazy"
                                    //    style={{ objectFit: 'fill' }}
                                    //                                    style={{ objectFit: 'contain' }}
                                    style={{ objectFit: 'crop' }}
                                />
                                {/* <ImageListItemBar
                                    title={item.name}
                                    subtitle={item.htmlText}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    }
                                /> */}
                            </ImageListItem>
                        </HtmlTooltip>
                    </>
                ))}
            </ImageList>
        </Box >
    };

    return _templates ? _properties.templatesAsMasonry ? (<Images></Images>) : (<Tiles></Tiles>) : <></>;
    // return _templates ? _properties.templatesAsMasonry ? (<Bricks></Bricks>) : (<Tiles></Tiles>) : <></>;

}

export default TemplatePicker;
