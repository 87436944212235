import store from "./Store";
import { getFromTo } from "../common/tools";
import i18n from 'i18next';

export const REFRESH_AUTHENTICATION = 'REFRESH_AUTHENTICATION';
export const SET_INVOICES_PERIOD = 'SET_INVOICES_PERIOD';
export const SET_ORDERS_PERIOD = 'SET_ORDERS_PERIOD';
export const SET_ORDERS_LAYOUT = 'SET_ORDERS_LAYOUT';
export const SET_CUSTOMER_TAB = 'SET_CUSTOMER_TAB';
export const INIT_CREATIVE = 'INIT_CREATIVE';
export const SET_EPAYMENT = 'SET_EPAYMENT';
export const RESET_EPAYMENT = 'RESET_EPAYMENT';
export const SET_DATAGRID_LOCALE_TEXT = 'SET_DATAGRID_LOCALE_TEXT';
export const SET_FORMX_READY = 'SET_FORMX_READY';
export const SET_FORMX_VALUES = 'SET_FORMX_VALUES';
export const RESET_FORMX_VALUES = 'RESET_FORMX_VALUES';
export const SET_UPSELLING_DONE = 'SET_UPSELLING_DONE';
export const SET_UPSELLING_TEXTFLOWS = 'SET_UPSELLING_TEXTFLOWS';
export const SET_UPSELLING_IMAGES = 'SET_UPSELLING_IMAGES';
export const STYLO_REQUEST = 'STYLO_REQUEST';
export const STYLO_DONE = 'STYLO_DONE';
export const STYLO_READY = 'STYLO_READY';
export const STYLO_SAVED = 'STYLO_SAVED';
export const LOAD_PAGE_DIMENSIONS_REQUEST = 'LOAD_PAGE_DIMENSIONS_REQUEST';
export const LOAD_PAGE_DIMENSIONS_SUCCESS = 'LOAD_PAGE_DIMENSIONS_SUCCESS';
export const LOAD_PAGE_DIMENSIONS_FAILURE = 'LOAD_PAGE_DIMENSIONS_FAILURE';
export const SET_TEMPLATES_PER_PAGE = 'SET_TEMPLATES_PER_PAGE';
export const LOAD_DESIGN_ALTERNATIVES_REQUEST = 'LOAD_DESIGN_ALTERNATIVES_REQUEST';
export const LOAD_DESIGN_ALTERNATIVES_SUCCESS = 'LOAD_DESIGN_ALTERNATIVES_SUCCESS';
export const LOAD_DESIGN_ALTERNATIVES_FAILURE = 'LOAD_DESIGN_ALTERNATIVES_FAILURE';
export const LOAD_UPSELLING_ALTERNATIVES_REQUEST = 'LOAD_UPSELLING_ALTERNATIVES_REQUEST';
export const LOAD_UPSELLING_ALTERNATIVES_SUCCESS = 'LOAD_UPSELLING_ALTERNATIVES_SUCCESS';
export const LOAD_UPSELLING_ALTERNATIVES_FAILURE = 'LOAD_UPSELLING_ALTERNATIVES_FAILURE';
export const SET_UPSELLING_COLUMNS = 'SET_UPSELLING_COLUMNS';
export const SET_MOTIF_STRUCTURE = 'SET_MOTIF_STRUCTURE';
export const RESET_PRESELECTED = 'RESET_PRESELECTED';

const state = () => {
    return store.getState();
};

const api = () => {
    return state().api.services.common;
};

const callAPI = (url, params) => {

    if (!params) params = {};
    params.credentials = "same-origin";     // needed by firefox in order to process cookies
    params.headers = params.headers || {};
    const lang = i18n.language;
    if (lang) {
        params.headers["Accept-Language"] = lang;
    }
    const sapSessionId = state().common.sapSessionId;
    if (sapSessionId) {
        if (-1 === url.indexOf('?')) {
            url += ('?sapSessionId=' + sapSessionId);
        }
        else {
            url += ('&sapSessionId=' + sapSessionId);
        }
    }

    return fetch(url, params);
};

export const setAuthentication = (authentication) => {
    return {
        type: 'SET_AUTHENTICATION',
        authentication: authentication
    };
};
export const getAuthProvider = () => {
    let url = api().ssoProviders.href;
    return {
        types: ['GET_AUTH_PROVIDER_REQUEST', 'GET_AUTH_PROVIDER_SUCCESS', 'GET_AUTH_PROVIDER_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const removeAuthentication = () => {
    return {
        type: 'REMOVE_AUTHENTICATION'
    };
};
export const getAuthentication = () => {
    let url = api().getAuthentication.href;
    return {
        types: ['GET_AUTHENTICATION_REQUEST', 'GET_AUTHENTICATION_SUCCESS', 'GET_AUTHENTICATION_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};
export const fetchUserProfile = () => {
    let url = api().userProfile.href;
    return {
        types: ['FETCH_USER_PROFILE_REQUEST', 'FETCH_USER_PROFILE_SUCCESS', 'FETCH_USER_PROFILE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};
export const passwordReminder = (email) => {
    let url = api().passwordReminder.href;
    return {
        types: ['PASSWORD_REMINDER_REQUEST', 'PASSWORD_REMINDER_SUCCESS', 'PASSWORD_REMINDER_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, {
            'method': 'POST',
            'body': JSON.stringify({ email }),
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};
// eslint-disable-next-line no-unused-vars
const createBusinessPartner = (businessPartner) => {
    let url = api().businesspartners.href,
        method = 'POST';
    url = url.replace('/{businessPartnerNumber}', '');
    const params = {
        'method': method,
        'body': JSON.stringify(businessPartner),
        'headers': {
            'content-type': 'application/json'
        }
    };
    return {
        types: ['SAVE_BP_REQUEST', 'SAVE_BP_SUCCESS', 'SAVE_BP_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, params)
    };
};

export const resetBpResponse = () => {
    return { type: 'RESET_BP_RESPONSE' };
};

export const resetCheckedUserNameValid = () => {
    return { type: 'RESET_CHECKED_USERNAME_VALID' };
};

export const saveBusinessPartner = (businessPartner, businessPartnerNumber) => {
    let url = api().businesspartners.href,
        method;

    if (businessPartnerNumber) {
        method = 'PUT';
        url = url.replace('{businessPartnerNumber}', businessPartnerNumber);
    }
    else {
        method = 'POST';
        url = url.replace('/{businessPartnerNumber}', '');
    }
    const params = {
        'method': method,
        'body': JSON.stringify(businessPartner),
        'headers': {
            'content-type': 'application/json'
        }
    };
    return {
        types: ['SAVE_BP_REQUEST', 'SAVE_BP_SUCCESS', 'SAVE_BP_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, params)
    };
};

export const setUserData = (value) => {
    return { type: 'SET_USER_DATA', value }
};

export const fetchBusinessPartner = (businessPartnerNumber) => {
    let url = api().businesspartners.href;
    url = url.replace("{businessPartnerNumber}", businessPartnerNumber);
    return {
        types: ['FETCH_BP_REQUEST', 'FETCH_BP_SUCCESS', 'FETCH_BP_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const fetchSalesReps = (businessPartner) => {
    let url, shouldCall;
    if (businessPartner) {
        url = businessPartner._links.salesReps.href;
        shouldCall = true;
    }
    else {
        url = null;
        shouldCall = false;
    }
    return {
        types: ['FETCH_SALES_REPS_REQUEST', 'FETCH_SALES_REPS_SUCCESS', 'FETCH_SALES_REPS_FAILURE'],
        shouldCallAPI: state => shouldCall,
        callAPI: () => callAPI(url)
    };
};

export const fetchOrders = (period, refreshCount) => {
    let ft = getFromTo(period);
    let url = period && state().BP.businessPartner._links && state().BP.businessPartner._links.orders ? state().BP.businessPartner._links.orders.href : null;
    if (url) {
        url = url.replace(/{&from}/, "&from=" + ft.from);
        url = url.replace(/refreshCount=0/, "refreshCount=" + refreshCount);
    } else {
        url = null;
    }
    return {
        types: ['FETCH_ORDERS_REQUEST', 'FETCH_ORDERS_SUCCESS', 'FETCH_ORDERS_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const additionalOrderData = (url) => {
    return {
        types: ['ADDITIONAL_ORDER_DATA_REQUEST', 'ADDITIONAL_ORDER_DATA_SUCCESS', 'ADDITIONAL_ORDER_DATA_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const loadFormxValues = (link) => {
    const url = link ? link.href : null;
    return {
        types: ['LOAD_FORMX_VALUES_REQUEST', 'LOAD_FORMX_VALUES_SUCCESS', 'LOAD_FORMX_VALUES_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url)
    };
};

export const fetchDrafts = () => {
    let url = state().BP.businessPartner._links && state().BP.businessPartner._links.drafts ? state().BP.businessPartner._links.drafts.href : null;
    //   if (url) url = url.replace('{businessPartnerNumber}', businessPartnerNumber);
    return {
        types: ['FETCH_DRAFTS_REQUEST', 'FETCH_DRAFTS_SUCCESS', 'FETCH_DRAFTS_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url)
    };
};

export const deleteDraft = (url) => {
    return {
        types: ['DELETE_DRAFT_REQUEST', 'DELETE_DRAFT_SUCCESS', 'DELETE_DRAFT_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url, { 'method': 'DELETE' })
    };
};
// eslint-disable-next-line no-unused-vars
const continueDraft = (orderCode) => {
    let url = api().readOrder.href;
    url = url.replace(/{orderCode}/, orderCode);
    return {
        types: ['CONTINUE_DRAFT_REQUEST', 'CONTINUE_DRAFT_SUCCESS', 'CONTINUE_DRAFT_FAILURE'],
        shouldCallAPI: state => Boolean(orderCode),
        callAPI: () => callAPI(url)
    };
};

export const releaseDraft = (order, id) => {
    const url = api().releaseDraft.href.replace('{id}', id);
    return {
        types: ['RELEASE_DRAFT_REQUEST', 'RELEASE_DRAFT_SUCCESS', 'RELEASE_DRAFT_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            url, {
            'method': 'PUT',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify(order)
        }
        )
    };
}


export const readOrder = (orderCode) => {
    let url = api().readOrder.href;
    url = url.replace(/{orderCode}/, orderCode);
    return {
        types: ['READ_ORDER_REQUEST', 'READ_ORDER_SUCCESS', 'READ_ORDER_FAILURE'],
        shouldCallAPI: state => Boolean(orderCode),
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const paymentStatus = (token) => {
    let url = api().paymentStatus.href;
    url = url.replace(/{token}/, token);
    return {
        types: ['PAYMENT_STATUS_REQUEST', 'PAYMENT_STATUS_SUCCESS', 'PAYMENT_STATUS_FAILURE'],
        shouldCallAPI: state => Boolean(token),
        callAPI: () => callAPI(url)
    };
};

export const repeatOrder = (orderCode) => {
    let url = api().readOrder.href;
    url = url.replace(/{orderCode}/, orderCode);
    return {
        types: ['REPEAT_ORDER_REQUEST', 'REPEAT_ORDER_SUCCESS', 'REPEAT_ORDER_FAILURE'],
        shouldCallAPI: state => Boolean(orderCode),
        callAPI: () => callAPI(url)
    };
};

export const editOrder = (orderCode) => {
    let url = api().readOrder.href;
    url = url.replace(/{orderCode}/, orderCode);
    return {
        types: ['EDIT_ORDER_REQUEST', 'EDIT_ORDER_SUCCESS', 'EDIT_ORDER_FAILURE'],
        shouldCallAPI: state => Boolean(orderCode),
        callAPI: () => callAPI(url)
    };
};

export const cancelOrder = (url, cancelInformation) => {
    let u = cancelInformation ? url.replace('false', 'true') : url;
    return {
        types: ['CANCEL_ORDER_REQUEST', 'CANCEL_ORDER_SUCCESS', 'CANCEL_ORDER_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(u, { 'method': 'DELETE' })
    };
};
// eslint-disable-next-line no-unused-vars
export const initOrder = (legalEntityTypeName) => {
    let url = api().initOrder.href;
    url = url.replace("{legalEntityTypeName}", legalEntityTypeName);
    return {
        types: ['INIT_ORDER_REQUEST', 'INIT_ORDER_SUCCESS', 'INIT_ORDER_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};

export const fetchInvoices = (period) => {
    let ft = getFromTo(period);
    let url = state().BP.businessPartner._links.invoices ? state().BP.businessPartner._links.invoices.href : null;
    if (url) {
        url = url.replace(/{.*}/, "?from=" + ft.from + "&to=" + ft.to);
    } else {
        url = null;
    }
    return {
        types: ['FETCH_INVOICES_REQUEST', 'FETCH_INVOICES_SUCCESS', 'FETCH_INVOICES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const callPerflog = (event) => {
    if (!(state().common && state().common.features && state().common.features.PERF_LOG && state().common.features.PERF_LOG.active))
        return { type: null };
    let url = api().perflog.href;
    return {
        types: ['CALL_PERFLOG_REQUEST', 'CALL_PERFLOG_SUCCESS', 'CALL_PERFLOG_FAILURE'],
        shouldCallAPI: state => Boolean(url),
        callAPI: () => callAPI(url, {
            'body': JSON.stringify(event),
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
}

export const setBpForModify = (businessPartner) => {
    return {
        type: 'SET_BP_FOR_MODIFY',
        businessPartner: businessPartner
    };
};
// eslint-disable-next-line no-unused-vars
const setBusinessPartner = (businessPartner) => {
    return {
        type: 'SET_BUSINESS_PARTNER',
        businessPartner: businessPartner
    };
};
// eslint-disable-next-line no-unused-vars
const removeBusinessPartner = () => {
    return {
        type: 'REMOVE_BUSINESS_PARTNER'
    };
};
export const setCustomerType = (customerType) => {
    return {
        type: 'SET_CUSTOMER_TYPE',
        value: customerType
    };
};
// eslint-disable-next-line no-unused-vars
const customerTypeChanged = () => {
    return {
        type: 'CUSTOMER_TYPE_CHANGED'
    }
};
// eslint-disable-next-line no-unused-vars
const setProperties = (properties) => {
    return {
        type: 'SET_PROPERTIES',
        value: properties
    };
};

export const services = () => {
    return {
        types: ['LOAD_SERVICES_REQUEST', 'LOAD_SERVICES_SUCCESS', 'LOAD_SERVICES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI('/webstore/service/index')
    };
};

export const fetchProperties = () => {
    let url = api().properties.href;
    return {
        types: ['FETCH_PROPERTIES_REQUEST', 'FETCH_PROPERTIES_SUCCESS', 'FETCH_PROPERTIES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};
// eslint-disable-next-line no-unused-vars
const privateServices = () => {
    return {
        types: ['LOAD_PRIVATE_SERVICES_REQUEST', 'LOAD_PRIVATE_SERVICES_SUCCESS', 'LOAD_PRIVATE_SERVICES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI('/webstore/service/index/private')
    };
};
// eslint-disable-next-line no-unused-vars
const businessServices = () => {
    return {
        types: ['LOAD_BUSINESS_SERVICES_REQUEST', 'LOAD_BUSINESS_SERVICES_SUCCESS', 'LOAD_BUSINESS_SERVICES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI('/webstore/service/index/business')
    };
};

export const urlParams = () => {
    return {
        type: 'URL_PARAMS'
    };
};

export const features = () => {
    return {
        types: ['LOAD_FEATURES_REQUEST', 'LOAD_FEATURES_SUCCESS', 'LOAD_FEATURES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI('/webstore/ft/features')
    };
};

export const setPaymentMethod = (value) => {
    return {
        type: 'SET_PAYMENT_METHOD',
        value: value
    };
};

// eslint-disable-next-line no-unused-vars
const resetEditionParts = () => {
    return {
        type: 'RESET_EDITION_PARTS',
        value: {}
    };
};



export const fetchEditionParts = (legalEntityTypeName, selectedMarket, selectedTemplate, selectedOffer, adText, defaultAdEdPartId) => {
    let callApi = Boolean(legalEntityTypeName && selectedMarket && selectedTemplate && selectedOffer && defaultAdEdPartId);
    let url = null;
    if (callApi) {
        url = api().editionPartsHierarchy.href;
        url = url.replace("{legalEntityTypeName}", legalEntityTypeName);
        url = url.replace("{marketId}", String(selectedMarket._id));
        url = url.replace("{templateId}", String(selectedTemplate._id));
        url = url.replace("{offerId}", String(selectedOffer._id));
        url = url.replace("{defaultAdEdPartId}", String(defaultAdEdPartId));
        if (adText) {
            url = url.replace("{adText}", encodeURIComponent(adText));
        }
        else {
            url = url.replace("{adText}", "");
        }
    }
    return {
        types: ['FETCH_EDITION_PARTS_REQUEST', 'FETCH_EDITION_PARTS_SUCCESS', 'FETCH_EDITION_PARTS_FAILURE'],
        shouldCallAPI: state => callApi,
        callAPI: () => callAPI(url)
    };
};

export const fetchAllEditionParts = () => {
    const url = api().editionParts.href;
    return {
        types: ['FETCH_ALL_EDITION_PARTS_REQUEST', 'FETCH_ALL_EDITION_PARTS_SUCCESS', 'FETCH_ALL_EDITION_PARTS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};

export const fetchEditions = () => {
    return {
        types: ['FETCH_EDITIONS_REQUEST', 'FETCH_EDITIONS_SUCCESS', 'FETCH_EDITIONS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().editions.href)
    };
};

export const fetchMarkets = () => {
    return {
        types: ['LOAD_MARKETS_REQUEST', 'LOAD_MARKETS_SUCCESS', 'LOAD_MARKETS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().markets.href)
    };
};

export const fetchOffers = () => {
    return {
        types: ['LOAD_OFFERS_REQUEST', 'LOAD_OFFERS_SUCCESS', 'LOAD_OFFERS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().offers.href)
    };
};

export const fetchOfferCriterias = () => {
    let url = api().criteria.href.replace("{criteriaSet}", "order");
    return {
        types: ['LOAD_OFFERCRITERIAS_REQUEST', 'LOAD_OFFERCRITERIAS_SUCCESS', 'LOAD_OFFERCRITERIAS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};


export const fetchTemplates = () => {
    return {
        types: ['LOAD_TEMPLATES_REQUEST', 'LOAD_TEMPLATES_SUCCESS', 'LOAD_TEMPLATES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().templates.href)
    };
};

export const fetchChannels = () => {
    return {
        types: ['LOAD_CHANNELS_REQUEST', 'LOAD_CHANNELS_SUCCESS', 'LOAD_CHANNELS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().channels.href)
    };
};

export const fetchDesignAlternatives = () => {
    if (state().masterData.selectedTemplate && state().masterData.selectedTemplate._links.alternatives) {
        let url = state().masterData.selectedTemplate._links.alternatives.href;
        url = url.replace('{legalEntityTypeName}', state().common.customerType);
        url = url.replace('{type}', "design");
        url = url.replace('{?marketId,businessPartnerId}', '')
        let market = state().masterData.selectedMarket;
        if (market) {
            url += ('?marketId=' + market._id);
        }
        let bp = state().BP.businessPartner;
        if (bp) {
            url += market ? '&' : '?';
            url += ('businessPartnerId=' + bp._id);
        }
        return {
            types: [LOAD_DESIGN_ALTERNATIVES_REQUEST, LOAD_DESIGN_ALTERNATIVES_SUCCESS, LOAD_DESIGN_ALTERNATIVES_FAILURE],
            shouldCallAPI: state => true,
            callAPI: () => callAPI(url)
        };
    }
};

export const fetchUpsellingAlternatives = () => {
    if (state().masterData.selectedTemplate && state().masterData.selectedTemplate._links.alternatives) {
        let url = state().masterData.selectedTemplate._links.alternatives.href;
        url = url.replace('{legalEntityTypeName}', state().common.customerType);
        url = url.replace('{type}', "upselling");
        url = url.replace('{?marketId,businessPartnerId}', '')
        let market = state().masterData.selectedMarket;
        if (market) {
            url += ('?marketId=' + market._id);
        }
        let bp = state().BP.businessPartner;
        if (bp) {
            url += market ? '&' : '?';
            url += ('businessPartnerId=' + bp._id);
        }
        return {
            types: [LOAD_UPSELLING_ALTERNATIVES_REQUEST, LOAD_UPSELLING_ALTERNATIVES_SUCCESS, LOAD_UPSELLING_ALTERNATIVES_FAILURE],
            shouldCallAPI: state => true,
            callAPI: () => callAPI(url)
        };
    }
};

export const fetchTemplateCriteria = (url) => {
    return {
        types: ['LOAD_TEMPLATE_CRITERIA_REQUEST', 'LOAD_TEMPLATE_CRITERIA_SUCCESS', 'LOAD_TEMPLATE_CRITERIA_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};

export const fetchTemplateComponents = (url) => {
    return {
        types: ['LOAD_TEMPLATE_COMPONENTS_REQUEST', 'LOAD_TEMPLATE_COMPONENTS_SUCCESS', 'LOAD_TEMPLATE_COMPONENTS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};

export const fetchTemplateOnlineId = (template, onlineIdPrefix) => {
    //    if (state().masterData.selectedTemplate && state().masterData.selectedTemplate._links.onlineId) {
    if (template && template._links.onlineId) {
        let url = template._links.onlineId.href + encodeURIComponent(onlineIdPrefix);
        return {
            types: ['FETCH_TEMPLATE_ONLINE_ID_REQUEST', 'FETCH_TEMPLATE_ONLINE_ID_SUCCESS', 'FETCH_TEMPLATE_ONLINE_ID_FAILURE'],
            shouldCallAPI: state => true,
            callAPI: () => callAPI(url)
        };
    }
};

export const fetchPaymentTypes = () => {
    return {
        types: ['LOAD_PAYMENT_TYPES_REQUEST', 'LOAD_PAYMENT_TYPES_SUCCESS', 'LOAD_PAYMENT_TYPES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().allPaymentTypes.href)
    };
};

export const fetchSalutations = () => {
    return {
        types: ['LOAD_SALUTATIONS_REQUEST', 'LOAD_SALUTATIONS_SUCCESS', 'LOAD_SALUTATIONS_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().salutations.href)
    };
};

export const fetchTitles = () => {
    return {
        types: ['LOAD_TITLES_REQUEST', 'LOAD_TITLES_SUCCESS', 'LOAD_TITLES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().titles.href)
    };
};

export const fetchCountries = () => {
    return {
        types: ['LOAD_COUNTRIES_REQUEST', 'LOAD_COUNTRIES_SUCCESS', 'LOAD_COUNTRIES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().countries.href)
    };
};

export const fetchCities = (key, countryCode) => {
    let url = api().citiesByName.href;
    url = url.replace('{name}', key);
    url = url.replace('{?country}', `?country=${countryCode}`);

    return {
        types: ['LOAD_CITIES_REQUEST', 'LOAD_CITIES_SUCCESS', 'LOAD_CITIES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url)
    };
};

export const fetchOrderTypes = () => {
    return {
        types: ['LOAD_ORDERTYPES_REQUEST', 'LOAD_ORDERTYPES_SUCCESS', 'LOAD_ORDERTYPES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().orderType.href)
    };
};


export const setOffer = (offerId, tracking) => {
    return {
        type: 'SET_OFFER',
        value: Number(offerId),
        tracking
    };
};

export const setMarket = (marketId, tracking) => {
    return {
        type: 'SET_MARKET',
        value: Number(marketId),
        tracking
    };
};

export const setTemplate = (templateId, tracking, isSuggestion) => {
    return {
        type: 'SET_TEMPLATE',
        value: Number(templateId),
        tracking,
        isSuggestion
    };
};

export const setEditionPart = (editionPartId, tracking) => {
    return {
        type: 'SET_EDITION_PART',
        value: Number(editionPartId),
        tracking
    };
};

export const setMotif = (params, rawText, uuid) => {
    return {
        type: 'SET_MOTIV',
        value: { params, rawText, uuid }
    };
};
// eslint-disable-next-line no-unused-vars
const setPreview = (value) => {
    return {
        type: 'SET_PREVIEW',
        value
    };
};

export const setTextFlows = (value) => {
    return {
        type: 'SET_TEXT_FLOWS',
        value
    };
};

export const addTextFlow = (value) => {
    return {
        type: 'ADD_TEXT_FLOW',
        value
    };
};

export const setUpsellingTextflows = (value) => {
    return {
        type: SET_UPSELLING_TEXTFLOWS,
        value
    };
};

export const setUpsellingImages = (value) => {
    return {
        type: SET_UPSELLING_IMAGES,
        value
    };
};

export const setTemplateUserFields = (value) => {
    return {
        type: 'SET_TEMPLATE_USER_FIELDS',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setTemplateUserFieldValues = (value) => {
    return {
        type: 'SET_TEMPLATE_USER_FIELD_VALUES',
        value
    };
};

export const setAdBoxFields = (value) => {
    return {
        type: 'SET_AD_BOX_FIELDS',
        value
    };
};

/**
 * Set stylo user fields
 */
export const setUserFields = (value) => {
    return {
        type: 'SET_USER_FIELDS',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setMotifCriterionValues = (value) => {
    return {
        type: 'SET_MOTIF_CRITERION_VALUES',
        value
    };
};

export const setFormxReady = value => {
    return {
        type: SET_FORMX_READY,
        value
    };
};

export const setFormxValues = (value) => {
    return {
        type: SET_FORMX_VALUES,
        value
    };
};

export const resetFormxValues = () => {
    return {
        type: RESET_FORMX_VALUES
    };
};

export const addMaterial = (value) => {
    return {
        type: 'ADD_MATERIAL',
        value
    };
};

export const removeMaterial = (value) => {
    return {
        type: 'REMOVE_MATERIAL',
        value
    };
};

export const setFormxValid = (value) => {
    return {
        type: 'SET_FORMX_VALID',
        value
    };
};

export const setDesiredDate = (value) => {
    return {
        type: 'SET_DESIRED_DATE',
        value
    };
};

export const setSelectedDates = (value) => {
    return {
        type: 'SET_SELECTED_DATES',
        value
    };
};

export const setVoucher = (value) => {
    return {
        type: 'SET_VOUCHER',
        value
    };
};

export const setAdBoxNumber = (value, tracking) => {
    return {
        type: 'SET_AD_BOX_NUMBER',
        value,
        tracking
    };
};

export const getAdBoxNumber = (abt, email) => {
    const request = {
        'type': abt.type,
        'typeId': abt._id,
        'branchOfficeCode': abt.branchOfficeCode,
        'email': abt.email
    }
    return {
        types: ['GET_AD_BOX_NUMBER_REQUEST', 'GET_AD_BOX_NUMBER_SUCCESS', 'GET_AD_BOX_NUMBER_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            abt._links.generateNumber.href, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify(request)
        })
    };
};

export const fetchAdBoxNumberTypes = (templateId) => {
    //            const template = state().masterData.selectedTemplate;
    const template = state().masterData.templates.find(t => t._id === templateId);
    const market = state().masterData.selectedMarket;
    let marketId = market ? market._id : template.marketIds[0];
    const url = template._links.adBoxNumberTypes.href.replace('{marketId}', marketId);
    return {
        types: ['FETCH_AD_BOX_NUMBER_TYPES_REQUEST', 'FETCH_AD_BOX_NUMBER_TYPES_SUCCESS', 'FETCH_AD_BOX_NUMBER_TYPES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            url, {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
}
// eslint-disable-next-line no-unused-vars
const setAuctionCredit = (value) => {
    return {
        type: 'SET_AUCTION_CREDIT',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setMarketingApproval = (value) => {
    return {
        type: 'SET_MARKETING_APPROVAL',
        value: Boolean(value)
    };
};

export const setCancelUrl = (value) => {
    return {
        type: 'SET_CANCEL_URL',
        value: value
    };
};

export const validateBankAccount = (iban, id) => {
    return {
        types: ['VALIDATE_BANK_ACCOUNT_REQUEST', 'VALIDATE_BANK_ACCOUNT_SUCCESS', 'VALIDATE_BANK_ACCOUNT_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().validateBankAccount.href, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify({ 'iban': iban, '_elementId': id })
        }
        )
    };
};

export const setForOrderSelectedPaymentType = (value) => {
    return {
        type: 'SET_FORORDERSELECTEDPAYMENTTYPE',
        value: value
    };
};

export const setServicePackageId = (value) => {
    return {
        type: 'SET_SERVICE_PACKAGE_ID',
        value
    };
};

export const addCriterion = (key, value) => {
    return {
        type: 'ADD_CRITERION',
        key,
        value
    };
};

export const setCriterionWithPattern = (value) => {
    return {
        type: 'SET_CRITERION_WITH_PATTERN',
        value
    };
};


export const setBankAccount = (bankAccount) => {
    return {
        type: 'SET_BANK_ACCOUNT',
        bankAccount
    };
};
// eslint-disable-next-line no-unused-vars
const setBankAccountId = (value) => {
    return {
        type: 'SET_BANK_ACCOUNT_ID',
        value: value
    };
};
export const setUseAlternativeIban = (value) => {
    return {
        type: 'SET_USE_ALTERNATIVE_IBAN',
        value
    };
};

export const setAlternativeIban = (value) => {
    return {
        type: 'SET_ALTERNATIVE_IBAN',
        value
    };
};

export const setAlternativeBic = (value) => {
    return {
        type: 'SET_ALTERNATIVE_BIC',
        value
    };
};

//         const setConfirmedOrder = (order) => {
//             return {
//                 type: 'SET_CONFIRMED_ORDER',
//                 order
//             };
//         };

export const resetOrder = () => {
    return {
        type: 'RESET_ORDER'
    };
};

export const setEditions = (value) => {
    return {
        type: 'SET_EDITIONS',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setSelectedEditionIds = (value) => {
    return {
        type: 'SET_SELECTED_EDITION_IDS',
        value
    };
};

export const resetPrintData = () => {
    return {
        type: 'RESET_PRINT_DATA'
    };
};

export const print = (pendingOrder) => {
    return {
        types: ['PRINT_ORDER_REQUEST', 'PRINT_ORDER_SUCCESS', 'PRINT_ORDER_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().printOrder.href, {
            'method': 'POST',
            'body': JSON.stringify(pendingOrder),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};

export const print2 = (pendingOrder) => {
    return {
        types: ['PRINT_ORDER_REQUEST2', 'PRINT_ORDER_SUCCESS2', 'PRINT_ORDER_FAILURE2'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().printOrder2.href, {
            'method': 'POST',
            'body': JSON.stringify(pendingOrder),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};
// eslint-disable-next-line no-unused-vars
const setExposeStatus = (json) => {
    return {
        type: 'SET_EXPOSE_STATUS',
        json
    };
};
// eslint-disable-next-line no-unused-vars
const exposePreview = (order) => {
    return {
        types: ['EXPOSE_PREVIEW_REQUEST', 'EXPOSE_PREVIEW_SUCCESS', 'EXPOSE_PREVIEW_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().exposePreview.href, {
            'method': 'POST',
            'body': JSON.stringify(order),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};
// eslint-disable-next-line no-unused-vars
const exposeUpdate = (order) => {
    return {
        types: ['EXPOSE_UPDATE_REQUEST', 'EXPOSE_UPDATE_SUCCESS', 'EXPOSE_UPDATE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().exposeUpdate.href, {
            'method': 'POST',
            'body': JSON.stringify(order),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};
// eslint-disable-next-line no-unused-vars
const sendEmailConfirmation = (pendingOrder) => {
    return {
        types: ['SEND_EMAIL_CONFIRMATION_REQUEST', 'SEND_EMAIL_CONFIRMATION_SUCCESS', 'SEND_EMAIL_CONFIRMATION_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().emailOrder.href, {
            'method': 'POST',
            'body': JSON.stringify(pendingOrder),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};

export const calculatePrice = (pendingOrder) => {
    return {
        types: ['CALCULATE_PRICE_REQUEST', 'CALCULATE_PRICE_SUCCESS', 'CALCULATE_PRICE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().calculateOrderPrice.href, {
            'method': 'POST',
            'body': JSON.stringify(pendingOrder),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};

export const calculatePossibleDates = (selectedTemplate, selectedOffer, selectedEditionIds, selectedEditionPart) => {
    let callApi = false,
        request = {};
    if (selectedTemplate && selectedOffer && selectedOffer.publicationCalendar && selectedEditionIds && selectedEditionPart) {
        callApi = true;
        request = {
            "duration": 360, // ??
            "editionIds": selectedEditionIds,
            "editionPartId": selectedEditionPart._id,
            "period": selectedOffer.weekdays,
            "start": null, // ??
            "stop": null,
            "templateId": selectedTemplate._id,
            "offerId": selectedOffer ? selectedOffer._id : 0
        };
    }
    return {
        types: ['CALCULATE_POSSIBLE_DATES_REQUEST', 'CALCULATE_POSSIBLE_DATES_SUCCESS', 'CALCULATE_POSSIBLE_DATES_FAILURE'],
        shouldCallAPI: state => callApi,
        callAPI: () => callAPI(
            api().calculatePossibleDates.href, {
            'method': 'POST',
            'body': JSON.stringify(request),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};

export const buy = (order) => {
    return {
        types: ['BUY_REQUEST', 'BUY_SUCCESS', 'BUY_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().createOrder.href, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify(order)
        }
        )
    };
}

export const updateOrder = (order, orderCode) => {
    const url = api().updateOrder.href.replace('{orderCode}', orderCode);
    return {
        types: ['UPDATE_ORDER_REQUEST', 'UPDATE_ORDER_SUCCESS', 'UPDATE_ORDER_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            url, {
            'method': 'PUT',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify(order)
        }
        )
    };
}
// eslint-disable-next-line no-unused-vars
const updateExpose = (order) => {
    const url = api().exposeUpdate.href;
    return {
        types: ['UPDATE_EXPOSE_REQUEST', 'UPDATE_EXPOSE_SUCCESS', 'UPDATE_EXPOSE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            url, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify(order)
        }
        )
    };
}
// eslint-disable-next-line no-unused-vars
const cancelExpose = (expose) => {
    const url = expose.stornoLink;
    return {
        types: ['CANCEL_EXPOSE_REQUEST', 'CANCEL_EXPOSE_SUCCESS', 'CANCEL_EXPOSE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            url, {
            'method': 'POST'
        }
        )
    };
}

export const saveDraft = (order) => {
    return {
        types: ['SAVE_DRAFT_REQUEST', 'SAVE_DRAFT_SUCCESS', 'SAVE_DRAFT_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().saveDraft.href, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            },
            'body': JSON.stringify(order)
        }
        )
    };
}

export const resetPrice = () => {
    return {
        type: 'RESET_PRICE'
    };
};

export const calculateOfferPrice = pendingOrder => {
    return {
        types: ['CALCULATE_OFFER_PRICE_REQUEST', 'CALCULATE_OFFER_PRICE_SUCCESS', 'CALCULATE_OFFER_PRICE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().calculateOfferPrice.href, {
            'method': 'POST',
            'body': JSON.stringify(pendingOrder),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};
// eslint-disable-next-line no-unused-vars
const calculateTemplatePrice = (pendingOrder) => {
    return {
        types: ['CALCULATE_TEMPLATE_PRICE_REQUEST', 'CALCULATE_TEMPLATE_PRICE_SUCCESS', 'CALCULATE_TEMPLATE_PRICE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().calculateTemplatePrice.href, {
            'method': 'POST',
            'body': JSON.stringify(pendingOrder),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};
// eslint-disable-next-line no-unused-vars
export const login = (userName, password, providerId, businessPartnerNo) => {
    return {
        types: ['LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().login.href, {
            'method': 'POST',
            'body': JSON.stringify({ userName, password, providerId, businessPartnerNo }),
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};

export const logout = () => {
    return {
        types: ['LOGOUT_REQUEST', 'LOGOUT_SUCCESS', 'LOGOUT_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            api().logout.href, {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
}

export const fetchTemplateStructure = (creativeUrl) => {
    const url = '/webstore/styloproxy/ServerRenderer?format=struct&odtUrl=' + creativeUrl;
    return {
        types: ['TEMPLATE_STRUCTURE_REQUEST', 'TEMPLATE_STRUCTURE_SUCCESS', 'TEMPLATE_STRUCTURE_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(
            url, {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json'
            }
        }
        )
    };
};

export const setMotifStructure = value => {
    return {
        type: SET_MOTIF_STRUCTURE,
        value
    };
};

// eslint-disable-next-line no-unused-vars
const setEntryPoint = (value) => {
    return {
        type: 'SET_ENTRY_POINT',
        value
    };
};

export const setPage = (value) => {
    return {
        type: 'SET_PAGE',
        value
    };
};
// eslint-disable-next-line no-unused-vars
export const setLanguage = (value) => {
    return {
        type: 'SET_LANGUAGE',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setLocale = (value) => {
    return {
        type: 'SET_LOCALE',
        value
    };
};

export const setDevice = (value) => {
    return {
        type: 'SET_DEVICE',
        value
    };
};

export const resetRepeatedOrder = (value) => {
    return {
        type: 'RESET_REPEATED_ORDER'
    };
};

export const resetEditedOrder = (value) => {
    return {
        type: 'RESET_EDITED_ORDER'
    };
};

export const confirmEPayment = () => {
    return {
        type: 'CONFIRM_EPAYMENT'
    };
};

export const setEPayment = value => {
    return {
        type: SET_EPAYMENT,
        value
    };
};
export const resetEPayment = () => {
    return {
        type: RESET_EPAYMENT
    };
};
// eslint-disable-next-line no-unused-vars
const setTracker = (value) => {
    return {
        type: 'SET_TRACKER',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setTrackingAccepted = (value) => {
    return {
        type: 'SET_TRACKING_ACCEPTED',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const trackEvent = (category, action, label) => {
    return {
        type: 'TRACK_EVENT',
        value: { category: category, action: action, label: label }
    };
};

export const setAdvertizer = (businessPartnerNo) => {
    return {
        type: 'SET_ADVERTIZER',
        businessPartnerNo
    };
};

export const checkPasswordAllowed = (decodedPw) => {
    let url = api().checkPasswordAllowed.href;
    return {
        types: ['CHECK_PASSWORDALLOWED_REQUEST', 'CHECK_PASSWORDALLOWED_SUCCESS', 'CHECK_PASSWORDALLOWED_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, {
            'method': 'POST',
            'body': JSON.stringify({ "decodedPw": decodedPw }),
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};

export const checkUserName = (userName) => {
    let url = api().check.href;
    return {
        types: ['CHECK_USERNAME_REQUEST', 'CHECK_USERNAME_SUCCESS', 'CHECK_USERNAME_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, {
            'method': 'POST',
            'body': JSON.stringify({ "userName": userName }),
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};

export const setOrderCatchword = (value) => {
    return {
        type: 'SET_ORDER_CATCHWORD',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setPerflogBaseEvent = (caller) => {
    let value = null;
    let version = "";
    let tenant = "";
    if (caller.services && caller.services.serviceVersionInfo) version = caller.services.serviceVersionInfo.versionString;
    if (caller.services && caller.services.tenant) tenant = caller.services.tenant.domain;
    value = {
        threadName: Date.now() + Math.floor(Math.random() * 10000),
        systemName: window.location.href.indexOf("bazaar") > -1 ? "bazaar" : "webstore",
        version: version,
        osName: navigator.userAgent,
        userName: null,
        branchOffice: tenant,
        sessionDuration: Date.now()
    };
    return {
        type: 'SET_PERFLOG_BASEEVENT',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const deleteServiceDependendPerfLogs = (value) => {
    return {
        type: 'DELETE_SERVICE_PERFLOGS',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setServiceDependendPerfLogs = (value) => {
    return {
        type: 'SET_SERVICE_PERFLOGS',
        value
    };
};

export const setOrderComment = (value) => {
    return {
        type: 'SET_ORDER_COMMENT',
        value
    };
};

export const setAlternativeAddress = (value) => {
    return {
        type: 'SET_ALTERNATIVE_ADDRESS',
        value
    };
};

export const setHasAlternativeAddress = (value) => {
    return {
        type: 'SET_HAS_ALTERNATIVE_ADDRESS',
        value
    };
};

export const setOrderType = (value) => {
    return {
        type: 'SET_ORDER_TYPE',
        value
    };
};

export const setPriceCalculation = (value) => {
    return {
        type: 'SET_PRICE_CALCULATION',
        value
    };
};

export const setOrderFixPrice = (value) => {
    return {
        type: 'SET_ORDER_FIX_PRICE',
        value
    };
};

export const setOrderIsCombi = (value) => {
    return {
        type: 'SET_ORDER_IS_COMBI',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setOrderConfirmation = (value) => {
    return {
        type: 'SET_ORDER_CONFIRMATION',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const setOrderCodeToReplace = (value) => {
    return {
        type: 'SET_ORDER_CODE_TO_REPLACE',
        value
    };
};
// eslint-disable-next-line no-unused-vars
const clearCaches = (cacheName) => {
    let url = api().clearCaches.href;
    if (cacheName) {
        url += '/' + cacheName;
    }
    return {
        types: ['CLEAR_CACHES_REQUEST', 'CLEAR_CACHES_SUCCESS', 'CLEAR_CACHES_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, {
            'method': 'DELETE',
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};

export const fetchImmomarktConfig = () => {
    let url = api().immomarktConfiguration.href;
    return {
        types: ['FETCH_IMMOMARKT_CONFIG_REQUEST', 'FETCH_IMMOMARKT_CONFIG_SUCCESS', 'FETCH_IMMOMARKT_CONFIG_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};

export const fetchClassmarketsConfig = () => {
    let url = api().classmarketsConfiguration.href;
    return {
        types: ['FETCH_CLASSMARKETS_CONFIG_REQUEST', 'FETCH_CLASSMARKETS_CONFIG_SUCCESS', 'FETCH_CLASSMARKETS_CONFIG_FAILURE'],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(url, {
            'method': 'GET',
            'headers': {
                'content-type': 'application/json'
            }
        })
    };
};

export const clearAlternatives = () => {
    return {
        type: 'CLEAR_ALTERNATIVES'
    };
};

export const setMotifColumns = (value) => {
    return {
        type: 'SET_MOTIF_COLUMNS',
        value
    };
};

export const setUpsellingColumns = (value) => {
    return {
        type: SET_UPSELLING_COLUMNS,
        value
    };
};

export const setMotifColoured = (value) => {
    return {
        type: 'SET_MOTIF_COLOURED',
        value
    };
};

export const confirmTermsAndConditions = (value) => {
    return {
        type: 'CONFIRM_TERMS_AND_CONDITIONS',
        value
    };
};

export const functionAction = () => {
    return dispatch => fetch("http://nb-hainz:9080/webstore/service/v1/resources/src/locales.json")
        .then(response => response.json().then(locales => dispatch({ type: 'LOCALES_SUCCESS', locales })));
};

export const setInvoicesPeriod = value => {
    return {
        type: SET_INVOICES_PERIOD,
        value
    };
};

export const setOrdersPeriod = value => {
    return {
        type: SET_ORDERS_PERIOD,
        value
    };
};

export const setOrdersLayout = value => {
    return {
        type: SET_ORDERS_LAYOUT,
        value
    };
};

export const setCustomerTab = value => {
    return {
        type: SET_CUSTOMER_TAB,
        value
    };
};

export const initCreative = value => {
    return {
        type: INIT_CREATIVE,
        value
    };
};

export const setDatagridLocaleText = value => {
    return {
        type: SET_DATAGRID_LOCALE_TEXT,
        value
    };
};

export const setUpsellingDone = value => {
    return {
        type: SET_UPSELLING_DONE,
        value
    };
};

export const setStyloRequest = () => {
    return {
        type: STYLO_REQUEST
    };
};

export const setStyloDone = () => {
    return {
        type: STYLO_DONE
    };
};

export const setStyloSaved = value => {
    return {
        type: STYLO_SAVED,
        value
    };
};

export const setStyloReady = value => {
    return {
        type: STYLO_READY,
        value
    };
};

export const fetchPageDimensions = () => {
    return {
        types: [LOAD_PAGE_DIMENSIONS_REQUEST, LOAD_PAGE_DIMENSIONS_SUCCESS, LOAD_PAGE_DIMENSIONS_FAILURE],
        shouldCallAPI: state => true,
        callAPI: () => callAPI(api().pageDimensions.href)
    };
};

export const setTemplatesPerPage = value => {
    return {
        type: SET_TEMPLATES_PER_PAGE,
        value
    };
};

export const resetPreselected = () => {
    return { type: RESET_PRESELECTED };
};

