import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Dialog, IconButton, Paper, Typography } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';
import {
    print2,
    resetPrintData,
} from '../../redux/Actions';
import {
    fullOrder,
    orderPrintUrl,
    orderToEdit,
    properties
} from '../../redux/Selectors';

export const DraftConfirmationDialog = ({ open, handleClose, order, userName }) => {
    const dispatch = useDispatch();
    const _fullOrder = useSelector((state) => fullOrder(state));
    const _orderPrintUrl = useSelector((state) => orderPrintUrl(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _properties = useSelector((state) => properties(state));
    const msg = _orderToEdit ? 'order.motifSave.success' : 'order.success';
    const { t } = useTranslation();

    // ----------------------------------------
    // order printing
    // ----------------------------------------  
    const printHandler = () => {
        dispatch(print2(_fullOrder));
    };

    let printWindow = undefined;
    useEffect(() => {
        if (_orderPrintUrl) {
            printWindow = window.open(_orderPrintUrl, "webstorePrintWindow", "width=840,height=840");
            dispatch(resetPrintData());
        }
    }, [_orderPrintUrl]);

    return order && userName && (
        <Dialog fullScreen open={open}>
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <Typography
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('draft.success.title')}</Typography>
                <Typography
                    sx={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: t('draft.success', { customerName: userName, orderCode: order.orderCode }) }}></Typography>
                <Box sx={{ marginTop: '5vh' }} >
                    <IconButton onClick={printHandler} >
                        <LocalPrintshopOutlinedIcon sx={{ color: _properties.appPrimaryColor }} />
                    </IconButton>
                    <IconButton onClick={handleClose} >
                        < ShortcutRoundedIcon />
                    </IconButton>
                </Box>
            </Paper >
        </Dialog >
    ) || (<></>);
}
