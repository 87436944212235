import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { properties } from '../redux/Selectors.js';
import {
    Box,
    Link
} from '@mui/material';
import '../configuration/i18n';

const AppLogo = () => {

    const _properties = useSelector(state => properties(state));
    const [target, setTarget] = useState("_blank");

    useEffect(() => {
        console.log(window.location.href);
        console.log(_properties.appLogoLink);
        if (_properties.appLogoLink && _properties.appLogoLink !== 0) {
            var localUrlParts = window.location.href.split('/');
            var appLogoLinkParts = _properties?.appLogoLink.split('/');
            console.log(localUrlParts);
            console.log(appLogoLinkParts);
            setTarget(!_properties?.appLogoLink.startsWith("http") || (appLogoLinkParts?.length > 2 && appLogoLinkParts[2] === localUrlParts[2]) ? "_self" : "_blank");
        }
    }, [_properties?.appLogoLink]);


    const Logo = () => {
        return (
            <img style={{ maxHeight: 50 }} alt="{_appTitle}" src="/webstore/service/v1/masterdata/resources/image/logo.png"></img>
        );
    }

    return (
        <Box sx={{ backgroundCcolor: _properties.logoBackgroundColor }}>
            {(_properties.appLogoLink !== undefined && _properties.appLogoLink !== 0) && (<Link href={_properties.appLogoLink} target={target}><Logo /></Link>)}
            {(!_properties.appLogoLink) && <Logo />}
        </Box>
    );
}

export default AppLogo;