// https://www.freecodecamp.org/news/how-to-add-localization-to-your-react-app/

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import { format as formatDate, formatRelative, formatDistance, isDate } from "date-fns";
import { de, enGB as en, fr, nl } from "date-fns/locale"; // import all locales we need

import store from "../redux/Store";
import { deDE, enUS, frFR, nlNL, } from '@mui/x-data-grid';
import { setDatagridLocaleText, setLanguage } from '../redux/Actions.js';

const locales = { de, en, fr, nl }; // used to look up the required locale

export const languages = [
    { key: 'de', name: 'Deutsch' },
    { key: 'en', name: 'English' },
    { key: 'fr', name: 'Français' },
    { key: 'nl', name: 'Nederlands' }
];

export const localeMap = {
    de: de,
    en: en,
    fr: fr,
    nl: nl,
};

const datagridMap = {
    de: deDE,
    en: enUS,
    fr: frFR,
    nl: nlNL,
};

export const maskMap = {
    de: '__.__.____',
    en: '__/__/____',
    fr: '__/__/____',
    nl: '__.__.____',
};

export const formatMap = {
    fr: 'MM/dd/yyyy',
    en: 'MM/dd/yyyy',
    nl: 'dd.MM.yyyy',
    de: 'dd.MM.yyyy',
};

const NS = 'translations';
let LNG = navigator.language.substring(0, 2);
const ENDPOINT = '/webstore/service/v1/resources/src/locales.json'

// TODO
/**
 * Messages from WS-Backend use single braces from interpolation, but i18n needs double braces.
 * @param {*} messages 
 */
function fixInterpolation(messages) {
    Object.keys(messages).forEach(key => {
        let value = messages[key];
        if (value) {
            value = value.replaceAll('{', '{{');
            value = value.replaceAll('}', '}}');
            messages[key] = value;
        }
    });
    return messages;
};

/**
 * Fetch messages fromm WS backend
 * @returns 
 */
export function loadMessages(language = 'de') {

    store.dispatch(setDatagridLocaleText(datagridMap[language].components.MuiDataGrid.defaultProps.localeText));

    axios.get(ENDPOINT, { headers: { "Accept-Language": language } }).
        then(res => {
            let messages = res.data;
            Object.keys(messages).forEach(key => {
                i18n.addResourceBundle(key, NS, fixInterpolation(messages[key]));
            });
            i18n.changeLanguage(language);
            store.dispatch(setLanguage(language));
            localStorage.setItem('ws-language', language);
        });
};

loadMessages(localStorage.getItem('ws-language') || LNG);

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: LNG,
        // inject an empty object, messages will be loaded from WS backend
        resources: {},
        ns: [NS],
        defaultNS: NS,
        interpolation: {
            escapeValue: false,
            format: (value, rawFormat, lng) => {
                const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
                switch (format) {
                    case 'uppercase':
                        return value.toUpperCase();
                    case 'number':
                        if (additionalValues[0] === "eur" || additionalValues[0] === "chf" || additionalValues[0] === "gbp"
                            || additionalValues[0] === "myr" || additionalValues[0] === "usd") {
                            return Intl.NumberFormat(lng, {
                                style: 'currency',
                                currency: additionalValues[0]
                            }).format(value);
                        } else {
                            return Intl.NumberFormat(lng, {
                            }).format(value);
                        }
                    case 'date':
                        if (isDate(value)) {
                            const dateFormat = additionalValues[0];
                            const locale = locales[lng];
                            switch (dateFormat) {
                                // https://date-fns.org/v2.28.0/docs/format
                                case 'short':
                                    return formatDate(value, 'P', { locale });
                                case 'long':
                                    return formatDate(value, 'PPPP', { locale });
                                case 'relative':
                                    return formatRelative(value, new Date(), { locale });
                                case 'ago':
                                    return formatDistance(value, new Date(), {
                                        locale,
                                        addSuffix: true
                                    });
                                default:
                                    return '';
                            }
                        }
                        break;
                    default:
                }
                return value;
            }
        }
    });

// ??
//i18n.languages = ['de'];

export default i18n; 