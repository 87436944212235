import React from 'react';

import { useEffect, useState } from 'react';

import {
    Box,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import OrderActionsMenu from './OrderActionsMenu.jsx';
import { cutCatchword } from '../../common/tools';

import { DraftActionsMenu } from './DraftActionsMenu.jsx';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderCardSmall = ({ item, itemHeight, dialogHandler }) => {

    const { t } = useTranslation();


    const stringList = array => {
        return array ? array.join(',') : '';
    };

    return (
        <Card
            onClick={() => { dialogHandler(item) }}
            key={'item.orderCode'}
            sx={{ height: '100%', border: 'none', boxShadow: 'none', cursor: 'pointer' }}>
            <Box sx={{ height: itemHeight / 2, border: 1, borderRadius: 2.5, borderColor: '#A0A0A0', p: 1, mt: 2, ml: 2, mr: 2, mb: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <CardMedia
                    component="img"
                    image={item._links.preview.href}
                    sx={{ maxHeight: itemHeight / 2 - 20, height: 'auto', width: 'auto', overflow: 'hidden', margin: 'auto', m: 4 }}
                />
            </Box>
            <CardContent sx={{ height: itemHeight / 4, p: 8 }}>
                <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between">
                    <Typography variant='templatePickerTitle'>
                        {item.orderCode}</Typography>
                    <Typography
                        variant='templatePickerSubtitle'>
                        {item.orderCatchword}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderPreview = ({ order, handleDialogClose, dialogOpen }) => {

    const { t } = useTranslation();

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={dialogOpen}
        >
            <DialogContent>
                <Stack direction="column" spacing={5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="dialogTitle">{t('customerdata.order.preview.title')} {order.orderCode}</Typography>
                    </Stack>
                    <Box>
                        <img style={{ width: 'auto', maxHeight: window.innerHeight / 2 }} src={order._links.preview.href}></img>
                    </Box>
                    <DialogActions>
                        <Button variant="contained" onClick={handleDialogClose}>{t('common.actions.close')}</Button>
                    </DialogActions>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderDetail = ({ order, handleDialogClose, dialogOpen, isDraft }) => {

    const { t } = useTranslation();

    console.log(dialogOpen ? "dialog open" : "dialog closed");
    console.log(isDraft === true ? "called OrderDetail for draft ..." : "called OrderDetail  for order ...");

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const [previewOpen, setPreviewOpen] = useState(false);

    const handleMenuOpen = () => {
        setMenuAnchorEl(document.getElementById('more-icon'));
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    }

    const handlePreviewClose = () => {
        setPreviewOpen(false);
    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={dialogOpen}
        >
            <DialogContent>
                <Stack direction="column" spacing={5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="dialogTitle">{order.orderCode}</Typography>
                        <MoreHorizIcon id="more-icon" onClick={handleMenuOpen} />
                    </Stack>
                    <Box onClick={() => setPreviewOpen(true)}>
                        <img style={{ cursor: 'pointer', width: 'auto', maxHeight: 200, border: 'solid 1px', padding: '4px' }} src={order._links.preview.href}></img>
                    </Box>
                    <Grid container spacing={2} justifyContent="space-between" >

                        <Grid item xs={12} sm={6} lg={3}>
                            <Typography variant="cardData">
                                {t('order.orderCatchword')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Typography variant="cardData">
                                {cutCatchword(order.orderCatchword, 30)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                            <Typography variant="cardData">
                                {t('order.editionPart')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            {
                                order.effectiveEditionPartNames.map(it => {
                                    return (
                                        <Box key={it}>
                                            <Typography variant="cardData">
                                                {it}
                                            </Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Typography variant="cardData">
                                {t('order.dates')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            {
                                order.effectiveDates.map(it => {
                                    return (
                                        <Box key={it}>
                                            <Typography variant="cardData">
                                                {t('common.date.short', { value: new Date(it.substring(0, 10)) })}
                                            </Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                            <Typography variant="cardData">
                                {t('order.editions')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Typography variant="cardData">
                                {order.effectiveEditionNames.map(en => {
                                    return (
                                        <Box key={en}>
                                            <Typography variant="cardData">
                                                {en}
                                            </Typography>
                                        </Box>
                                    )
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Typography variant="cardData">
                                {t('order.orderPrice')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Typography variant="cardData">
                                {t('global.currency.' + ('EUR'), { value: order.orderPrice.price })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>

                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>

                        </Grid>
                    </Grid>
                </Stack>
                <DialogActions>
                    <Button variant="contained" onClick={handleDialogClose}>{t('common.actions.close')}</Button>
                </DialogActions>
                {!isDraft && (<OrderActionsMenu
                    menuAnchorEl={menuAnchorEl}
                    isMenuOpen={isMenuOpen}
                    handleMenuClose={handleMenuClose}
                    selectedOrder={order} />)}
                {isDraft && (<DraftActionsMenu
                    menuAnchorEl={menuAnchorEl}
                    isMenuOpen={isMenuOpen}
                    handleMenuClose={handleMenuClose}
                    selectedOrder={order} />)}

            </DialogContent>
            <OrderPreview handleDialogClose={handlePreviewClose} dialogOpen={previewOpen} order={order} />

        </Dialog>
    );
};


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderCard = ({ item, itemHeight }) => {

    const { t } = useTranslation();
    const stringList = array => {
        return array ? array.join(',') : '';
    };

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const dateList = array => {
        return array ? stringList(array.map(d => t('common.date.short', { value: new Date(d.substring(0, 10)) }))) : '';
    };

    return (
        <Card key={'item.orderCode'} sx={{ height: '100%', border: 'none', boxShadow: 'none' }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ backgroundColor: 'primary.main' }} aria-label="recipe">
                        {item.effectiveEditionNames[0]}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                titleTypographyProps={{ variant: 'orderCardHeaderTitle' }}
                title={t('common.date.ago', { value: new Date(item.effectiveDates[0]) })}
                subheaderTypographyProps={{ variant: 'orderCardHeaderSubheader' }}
                subheader={item.effectiveEditionNames[0]}
                sx={{ height: itemHeight / 4 }}
            />
            <Box sx={{ height: itemHeight / 2, border: 1, borderRadius: 2.5, borderColor: '#A0A0A0', p: 1, mt: 2, ml: 2, mr: 2, mb: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <CardMedia
                    component="img"
                    image={item._links.preview.href}
                    sx={{ maxHeight: itemHeight / 2 - 20, height: 'auto', width: 'auto', overflow: 'hidden', margin: 'auto', m: 4 }}
                />
            </Box>
            <CardContent sx={{ height: itemHeight / 4, p: 8 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between">
                    <Typography variant='oderCardTitle'>
                        {stringList(item.effectiveEditionPartNames)}</Typography>
                    <Typography
                        variant='orderCardSubtitle'>
                        {dateList(item.effectiveDates)}
                    </Typography>
                </Stack>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
        </Card>
    )
}

export default OrderCard;
