import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack, Typography, Grid, Skeleton } from '@mui/material';
import {
    selectedMarket, selectedOffer, selectedEditionIds, editionDisplayNames, customerType,
    orderPriceResponse
} from '../../redux/Selectors.js';
import { EditionsPicker } from '../../components/order/EditionsPicker';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import SelectedMarket from '../components/SelectedMarket';
import { getPage } from '../../common/navigationTools.js';
import PriceArea from '../../components/common/PriceArea.jsx';


/**
 * @returns 
 */
export const Editions = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const DEVICE = 'mobile';

    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _selectedEditionIds = useSelector((state) => selectedEditionIds(state));
    const _editionDisplayNames = useSelector(state => editionDisplayNames(state));

    const back = () => {
        navigate(getPage('offers', DEVICE));
    };

    const next = () => {
        var minEditionsSelectable = _selectedOffer.minEditionsSelectable;
        var maxEditionsSelectable = _selectedOffer.maxEditionsSelectable;
        var editionCount = _selectedEditionIds ? _selectedEditionIds.length : 0;
        if (editionCount < minEditionsSelectable) {
            if (minEditionsSelectable === maxEditionsSelectable) {
                //   setMsgText(t('order.order.minMaxEditionsSelectable', { count: editionCount, min: _selectedOffer.minEditionsSelectable, max: _selectedOffer.maxEditionsSelectable }));

            } else {
                //       setMsgText(t('order.minEditionsSelectable', { count: editionCount, min: _selectedOffer.minEditionsSelectable }));
            }
            //     setMsgAnchorRef(editionsCardRef);
            //    setMsgOpen(true);
            return;
        }
        navigate(getPage('schedule', DEVICE));
    };

    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket market={_selectedMarket}></SelectedMarket>
                <Divider />
                <Typography variant="mobilePageSubTitle">{t('m.editions.select')}</Typography>
                <Box sx={{ width: '100%', height: '50vh' }}>
                    <EditionsPicker variant='mobile' contextId="card" ignoreLocalPosition={false} /* ref={editionsCardRef}  onClose={() => setLayout(1)}*/ />
                </Box>
                <Box sx={{ pt: 25 }}>
                    <Grid container >
                        <Grid item xs={5} >
                            <Typography variant="mobilePageLabel">
                                {_editionDisplayNames?.includes(' \u2022 ') ? t('m.order.editions') : t('m.order.edition')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="mobilePageLabel">
                                {_editionDisplayNames ? _editionDisplayNames : ''}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ pt: 6 }}>
                            <PriceArea />
                        </Grid>
                    </Grid>
                </Box>

            </Stack>
            <NavigationBar
                back={{ clicked: back, label: t('m.editions.back') }}
                next={{ clicked: next, label: t('m.editions.next') }}>
            </NavigationBar>

        </Stack>
    );
}

export default Editions;
