import React, { createContext, Suspense, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Button, Box, Stack, Dialog, Skeleton } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import { EditionsPicker } from './EditionsPicker';
import { selectedOffer } from '../../redux/Selectors.js';
import { EnlargeIcon } from '../../common/widgetTools.js';

export const EditionsCard = React.forwardRef(({ height, width, onClose }, ref) => {
    const { t } = useTranslation();

    const pickerRef = useRef();
    const pickerDialogRef = useRef();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [ignoreLocalPosition, setIgnoreLocalPosition] = useState(false);


    const openPickerDialog = () => {
        setDialogOpen(true);
    }

    const closePickerDialog = () => {
        setDialogOpen(false);
        pickerDialogRef?.current?.saveEditions();
        setIgnoreLocalPosition(true);
    }

    /*   useEffect(() => {
           pickerRef?.current?.changeActivePicker(dialogOpen)
           pickerDialogRef?.current?.changeActivePicker(!dialogOpen)
       }, [dialogOpen]) */


    const EditionsPickerDialog = (props) => {
        return (
            <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={dialogOpen}>
                <React.Fragment>
                    <Stack
                        sx={{ m: 20 }}>
                        <EditionsPicker contextId='dialog' ref={pickerDialogRef} ignoreLocalPosition={true} />
                        <Stack width="100%"
                            sx={{ m: '1rem' }}
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}>
                            <Box width="100px">
                                <Button variant="navigationAction" onClick={closePickerDialog}  >{t('common.actions.close')}</Button>
                            </Box>
                        </Stack>
                    </Stack>
                </React.Fragment>
            </Dialog>
        );
    }

    return (
        <>
            <Card
                variant='outlined'
                sx={{ width: '100%', maxWidth: width }}>
                <AreaCardHeader
                    sx={{ height: 38 }}
                    titleTypographyProps={{ variant: 'areaCardHeader' }}
                    title={t('order.editionsCardTitle')}
                    onClose={onClose}
                    action={openPickerDialog}
                >
                    <EnlargeIcon />
                </AreaCardHeader>
                <CardContent
                    sx={{ height: height - 40, width: width, overflowY: "scroll" }}
                    alignitems='center'>
                    {dialogOpen === false && <EditionsPicker width={width} height={height} contextId="card" ignoreLocalPosition={ignoreLocalPosition} ref={pickerRef}></EditionsPicker>}
                </CardContent>
            </Card >
            <EditionsPickerDialog />
        </>
    );

});

