import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    offers, selectedOffer, customerType, orderPrice, selectedMarket,
    device
} from '../../redux/Selectors.js';

import { Box, Typography, Button, Skeleton, Stack } from '@mui/material';
import { DEVICE_DESKTOP } from '../../common/navigationTools.js';



/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PriceArea = ({ navAction, displayOffer, displayPriceText, displayMoreOfferText, displayMorePriceText, noDistanceBox }) => {
    const { t } = useTranslation();
    const _offers = useSelector((state) => offers(state));
    //    const _offerPrices = useSelector((state) => offerPrices(state));
    const _customerType = useSelector((state) => customerType(state));
    const _orderPrice = useSelector((state) => orderPrice(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _selectedMarket = useSelector(state => selectedMarket(state));
    const _device = useSelector(state => device(state));

    const [price, setPrice] = useState();
    const [priceText, setPriceText] = useState('');
    const [priceTextVat, setPriceTextVat] = useState('');

    useEffect(() => {
        if (_orderPrice && _orderPrice.price !== null && _orderPrice.price !== 0) {
            let currency = _orderPrice.currency || 'EUR';
            let value = _orderPrice.price;
            setPrice({ currency, value });
            setPriceText(_customerType === 'private' ? t('order.price.gross.long') : t('order.price.net.long'));
            setPriceTextVat(_customerType === 'private' ? t('order.price.gross.vat') : t('order.price.net.vat'));
        } else if (_orderPrice?.price === 0 && _selectedMarket?.freeOfCharge === true) {
            setPrice({ 'currency': 'EUR', 'value': 0 })
        } else {
            setPrice(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderPrice, _selectedMarket]);

    return (
        <Box sx={{ height: '100%', width: '100%' }} >
            <Stack sx={{ height: '100%', justifyContent: 'space-between' }}>
                {displayOffer && (<><Typography sx={{ fontSize: 14, ml: 0, pl: 0 }}>{t('offer.selectedOffer')}</Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold', ml: 0, pl: 0 }}>{_selectedOffer?.name}</Typography></>)}
                {navAction && _offers?.length > 1 && (<Button variant='link' sx={{ pl: 0 }} onClick={() => { navAction(); }}>
                    <Typography sx={{ textDecoration: 'underline', fontSize: 14, ml: 0, pl: 0 }}>
                        {t('offer.moreOffers', { count: _offers.length - 1 })}
                    </Typography>
                </Button>)}
                {displayMoreOfferText && displayOffer && !navAction && _offers?.length > 1 && (<><Typography sx={{ fontSize: 14, ml: 0, pl: 0 }}>{t('offer.moreOffersNext')}</Typography></>)}
                {displayPriceText && (<Typography sx={{ fontSize: 14, ml: 0, pl: 0, pt: 20 }}>{priceText}</Typography>)}
                {/*!navAction &&*/ ((displayMoreOfferText === false && displayOffer === false) && !noDistanceBox) && (<Box sx={{ height: 25, display: 'block' }} />)}
                {_device === DEVICE_DESKTOP ? (<Box>
                    {price?.value || (price?.value === 0 && _selectedMarket?.freeOfCharge) ? (
                        <Stack direction='column'>
                            <Typography sx={{ textAlign: 'end', fontSize: 40, fontWeight: 'bold', ml: 0, pl: 0 }}>{t('global.currency.' + price.currency, { value: price.value })}
                            </Typography>
                            {!displayPriceText && (<Typography sx={{ textAlign: 'end', fontSize: 10, fontWeight: 'bold', ml: 0, pl: 0 }}>{priceTextVat}
                            </Typography>)}
                            {displayMorePriceText && (<Typography sx={{ textAlign: 'end', fontSize: 10, fontWeight: 'bold', ml: 0, pl: 0 }}>{t('order.price.more')}
                            </Typography>)}
                        </Stack>
                    ) :
                        (<Skeleton variant="text" height={50} ></Skeleton>)}
                </Box>) :
                    (
                        <Stack direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}>
                            <Typography variant="mobilePageLabel">
                                {_customerType === 'private' ? t('m.order.price') : t('m.order.price.net')}:
                            </Typography>
                            {
                                price ? (
                                    <Typography variant="mobilePageTitle">{t('global.currency.' + price.currency, { value: price.value })}
                                    </Typography>
                                ) : <Skeleton variant='text' animation="wave" width="20vw" height={48} />}
                        </Stack>
                    )}
            </Stack>
        </Box>
    );

}

export default PriceArea;