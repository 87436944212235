import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { LayoutSelector } from '../creative/CreativeLayouts';
import { selectedTemplate } from '../../redux/Selectors';
import { Stack } from '@mui/material';
import useCustomStyles from '../../common/useCustomStyles';

/**
 * 
 * @param {*} title 
 * @returns 
 */
export const AreaCardHeader = ({ title, navAction, onClose, handleExpand, fullCard, changeLayout, action, ...props }) => {

    const _selectedTemplate = useSelector((state) => selectedTemplate(state));

    const [displayFullCard, setDisplayFullCard] = useState(fullCard);

    const theme = useTheme();

    useEffect(() => {
        setDisplayFullCard(fullCard);
    }, [fullCard]);


    const NavButton = () => {
        return (
            navAction ?
                <Box sx={{ height: 38 }} >
                    <IconButton aria-label="settings" onClick={() => { navAction(); }}>
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
                :
                <></>
        )

    }

    const ActButton = () => {
        return (
            action ?
                <Box sx={{ height: 38 }} >
                    <IconButton aria-label="settings" onClick={() => { action(); }}>
                        {props.children}
                    </IconButton>
                </Box>
                :
                <></>
        )

    }
    const CloseButton = () => {
        return (
            onClose ?
                <Box sx={{ height: 38 }} >
                    <IconButton aria-label="settings" onClick={() => { onClose(); }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                :
                <></>
        )

    }

    const CollapseButton = () => {
        return (
            handleExpand ?
                <Box sx={{ height: 38 }} >
                    <IconButton aria-label="settings" onClick={() => { handleExpand(displayFullCard); }}>
                        {displayFullCard ? <ExpandMoreSharpIcon /> : <ExpandLessSharpIcon />}
                    </IconButton>
                </Box>
                :
                <></>
        )

    }

    const LayoutButtons = () => {
        return (
            changeLayout ?
                <Box sx={{ height: 38 }}>
                    <LayoutSelector template={_selectedTemplate} setLayout={changeLayout}></LayoutSelector>
                </Box>
                :
                <></>
        )
    }

    const ActionButton = () => {
        if (handleExpand) {
            return (<Stack direction='row' justifyContent="flex-end" spacing={0}><LayoutButtons /><CollapseButton /></Stack>);
        } else if (onClose) {
            return (<CloseButton />);
        } else if (navAction) {
            return (<NavButton />);
        } else if (action) {
            return (<ActButton />);
        } else {
            return (<></>);
        }
    }

    const buttonStyles = (theme) => ({
        action: {
            marginTop: -15,
        },
    });

    const classes = useCustomStyles(buttonStyles, theme);


    console.log(JSON.stringify(classes));
    console.log(classes?.action);

    return (
        <Box>
            <CardHeader title={title}
                titleTypographyProps={{ variant: 'areaCardHeader' }}
                sx={{ height: 38 }}
                action={<ActionButton />}
                classes={{ action: classes?.action }}
            >
            </CardHeader>
            <Divider variant="middle" />
        </Box>
    );
}

export default AreaCardHeader;
