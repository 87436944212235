import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';

// https://medium.com/@souadelmansouri2018/solving-the-makestyles-compatibility-issue-in-react-18-a-comprehensive-guide-235cc5d671c
const useCustomStyles = (styles, theme) => {
    const [classes, setClasses] = useState(null);

    useEffect(() => {
        if (!classes) {
            const evaluatedStyles = typeof styles === 'function' ? styles(theme) : styles;
            const generated = {};

            for (const key in evaluatedStyles) {
                generated[key] = css(evaluatedStyles[key]);
            }

            setClasses(generated);
        }
    }, [styles, theme, classes]);

    // Return the generated classes
    console.log("return " + JSON.stringify(classes));
    return classes;
};

export default useCustomStyles;